import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthenticationGuardService } from '../app/authentication-guard.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlSerializer } from '@angular/router';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { UtilsService } from '../app/services/utils/utils.service'
import { ChatService } from '../app/services/chat/chat.service'
import { DefaultUrlSerializer, UrlTree } from '@angular/router';
import { NavbarService } from './services/navbar/navbar.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutComponent } from './components/about/about.component';
import { KosherComponent } from './components/kosher/kosher.component';
import { ContactComponent } from './components/contact/contact.component';
import { NoPageFoundComponent } from './components/no-page-found/no-page-found.component';
import { CurrenciesComponent } from './components/currencies/currencies.component';
import { KosherZmanimComponent } from './components/kosher-zmanim/kosher-zmanim.component';
import { MailComponent } from './components/mail/mail.component';
import { BottomNavComponent } from './components/bottom-nav/bottom-nav.component';
import { MySitesComponent } from './components/my-sites/my-sites.component';
import { UserButtonsComponent } from './components/user-buttons/user-buttons.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AddSiteComponent } from './components/add-site/add-site.component';
import { RemoveSiteComponent } from './components/remove-site/remove-site.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { EnterSiteComponent } from './components/enter-site/enter-site.component';
import { VersionsComponent } from './components/versions/versions.component';
import { InstallInstructionsComponent } from './components/install-instructions/install-instructions.component';
import { DailyAppsComponent } from './components/daily-apps/daily-apps.component';
import { UploadImageComponent } from './components/upload-image/upload-image.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HomeCarouselComponent } from './components/home-carousel/home-carousel.component';
import { ChatInboxComponent } from './components/chat-inbox/chat-inbox.component';
import { VerifyCodeModalComponent } from './components/verify-code-modal/verify-code-modal.component';
import { TechComponent } from './components/tech/tech.component';
import { TechLoginComponent } from './components/tech-login/tech-login.component';
import { TechExitComponent } from './components/tech-exit/tech-exit.component';
import { CopyEmailsGuideComponent } from './components/copy-emails-guide/copy-emails-guide.component';
import { HomeLoggedInComponent } from './components/home-logged-in/home-logged-in.component';
import { HomeMainComponent } from './components/home-main/home-main.component';
import { MySitesExpandedComponent } from './components/my-sites-expanded/my-sites-expanded.component';

import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AddSiteArachimComponent } from './components/add-site-arachim/add-site-arachim.component';
import { InstallNativN4Component } from './components/install-nativ-n4/install-nativ-n4.component';
import { PrivacyPolicyComponent } from './modules/docs/privacy-policy/privacy-policy.component';

export function TranslationLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
    // Optional Step: Do some stuff with the url if needed.

    // If you lower it in the optional step 
    // you don't need to use "toLowerCase" 
    // when you pass it down to the next function
    console.log("url before: " + url)
    if (url.indexOf("channels-admin") == -1 && url.indexOf("AddSite.aspx") == -1 && url.indexOf("add-sites") == -1 && url.indexOf("Addsite.aspx") == -1 && url.indexOf("PrivacyPolicy") == -1) {
      console.log("url toLowerCase")
      return super.parse(url.toLowerCase());

    }
    else {
      return super.parse(url);
    }
  }
}

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'enativ.com' // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: '#191818c9'
    },
    button: {
      background: '#cd2033'
    }
  },
  theme: 'classic',
  type: 'info'
};

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    KosherComponent,
    ContactComponent,
    NoPageFoundComponent,
    CurrenciesComponent,
    KosherZmanimComponent,
    MailComponent,
    BottomNavComponent,
    MySitesComponent,
    UserButtonsComponent,
    AddSiteComponent,
    RemoveSiteComponent,
    UserProfileComponent,
    EnterSiteComponent,
    VersionsComponent,
    InstallInstructionsComponent,
    DailyAppsComponent,
    UploadImageComponent,
    FeedbackComponent,
    NavbarComponent,
    HomeCarouselComponent,
    ChatInboxComponent,
    VerifyCodeModalComponent,
    TechComponent,
    TechLoginComponent,
    TechExitComponent,
    CopyEmailsGuideComponent,
    HomeLoggedInComponent,
    HomeMainComponent,
    MySitesExpandedComponent,
    AddSiteArachimComponent,
    InstallNativN4Component,
    PrivacyPolicyComponent
  ],
  imports: [
    NgMultiSelectDropDownModule.forRoot(),
    CommonModule,
    RouterModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPageScrollCoreModule.forRoot({ duration: 300 }),
    NgxPageScrollModule,
    BrowserAnimationsModule,
    NgxDatatableModule,
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useFactory: TranslationLoaderFactory, deps: [HttpClient] }
    }),
    NgcCookieConsentModule.forRoot(cookieConfig),
    NgxSpinnerModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [Title,
    NavbarService,
    AuthenticationGuardService,
    UtilsService,
    ChatService,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    {
      provide: 'externalUrlRedirectResolver',
      useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        window.location.href = window.location.protocol + (route.data as any).externalUrl;
      }
    },
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer
    },
    BnNgIdleService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
