<div class="home-login-page">
    <button #scrollBtn (click)="topFunction()" class="btn-nativ scroll-btn" title="Go to top"><i
            class="fas fa-chevron-up"></i></button>
    <!-- <button (click)="toggleChat(true)" class="btn-nativ scroll-btn chat-btn"><img
            src="../../../assets/images/chat/open-btn-1.png" /></button>
    <div *ngIf="isShowChat">
        <app-chat-inbox [isShow]="isShowChat" (isShowChatEvent)="toggleChat($event)"></app-chat-inbox>
    </div> -->
    <div class="new-style user-header first-div">
        <div *ngIf="!displayInSmallScreen" class="user-div responsive-div row white-background">
            <!-- [ngClass]="showAdvs?'white-background':''"> -->
            <div *ngIf="showAdvs" class="col-md-2 text-center right-adv ext-adv-div"
                (click)="navigateToAdvSite(advsData[0].link,'right')">
                <div class="col-md-10 text-center mx-auto img-adv-div">
                    <img class="adv-img" [src]="advsData[0].src" />
                </div>
            </div>
            <!-- <div *ngIf="showAdvs" class="col-md-2 text-center right-adv ext-adv-div">
                <iframe scrolling="no" class="" frameBorder="0" class="adv-iframe"
                    src="../../../assets/images/advs/ezer-mezion/ezer_mezion180x520.html"></iframe>
                <span style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 10;"
                    (click)="navigateToAdvSite(advsData[0].link,'right')"></span>

            </div> -->
            <div class="col-8 responsive-div main-center-div">
                <div class="details-div text-center">
                    <div class="user-data-wrapper justify-content-center align-items-center text-center">
                        <div class="user-data">
                            {{ 'home.loggedin.header' | translate }}
                            <div class="user-details">
                                {{firstname}} {{lastname}}
                                <br />
                                {{ 'form.username' | translate }}: {{username}}
                            </div>
                            <div *ngIf="noNativVersion && !userServer" class="user-data text-center user-div login"
                                (click)="nativLogin();">
                                <a class="btn-nativ pending-docs no-nativ">
                                    אין משתמש נתיב מחובר <br />
                                    <div class="bold"> התחבר כעת</div>
                                </a>
                            </div>
                            <div *ngIf="noNativVersion && userServer" class="user-data text-center user-div login">
                                <a class="btn-nativ pending-docs no-nativ-server">
                                    שימו לב, תוכנת נתיב אינה מותקנת במחשב זה
                                    והוא לא יהיה מסונן מחוץ לרשת זו.
                                </a>
                            </div>
                            <a *ngIf="!noNativVersion && !refreshing" class="btn-nativ refresh"
                                (click)="refreshNativ()">{{
                                'global.refresh' |
                                translate }}</a>
                            <!-- <button *ngIf="!noNativVersion && refreshing" class="btn-nativ refresh">{{ 'global.refresh-now' |
                                            translate
                                            }}...</button> -->
                            <!-- <button *ngIf="!noNativVersion && refreshing" class="btn-nativ refresh refreshing">{{
                                            'global.refresh-now' | translate }}<i class="fa fa-spinner fa-spin"></i></button> -->
                            <button *ngIf="!noNativVersion && refreshing" class="btn-nativ refresh refreshing">
                                <div>{{ 'global.refresh-now' | translate }}</div>
                                <img src='../../../assets/images/new/nativ-gif-2.gif' />
                                <!-- <ngx-spinner *ngIf="!noNativVersion && refreshing"
                                                bdColor="rgba(234,230,230,0.63)" size="small" color="#fff" type="square-jelly-box"
                                                [fullScreen]="false" template=" <img src='../../../assets/images/new/nativ-gif-2.gif' /> ">
                                            </ngx-spinner> -->
                            </button>
                            <div *ngIf="pendingMsgsCount > 0" class="pending-docs-div">
                                <div class="col-md-12 pending-doc-div" *ngFor="let doc of pendingDocs;">
                                    <button class="btn-nativ pending-docs pending-btn"><span class="msg-cnt">1</span>
                                        <p (click)="navigateToDoc(doc.docType,doc.docId,doc.url)">{{
                                            'home.loggedin.pending-docs.subject-new' | translate }}: {{doc.title}}
                                        </p>
                                    </button>
                                    <a (click)="closeDoc(doc.docId)">מחק הודעה</a>
                                </div>
                            </div>
                        </div>
                        <div class="version">
                            <div *ngIf="isUserLoggedIn && !noNativVersion" class="version-div"
                                [ngClass]="{'upgrade': toUpgrade}">
                                גירסת נתיב במחשב זה {{ nativVersion }}
                            </div>
                            <button *ngIf="toUpgrade" (click)="updateVersion()"
                                class="btn-nativ upgrade">{{'home.loggedin.version.upgrade-btn' | translate
                                }}</button>
                            <div *ngIf="updatedVersion" class="version-div">
                                גירסה עדכנית!
                            </div>
                        </div>
                    </div>
                    <!-- <div class="version asd">
                                        <div *ngIf="isUserLoggedIn && !noNativVersion" class="version-div"
                                            [ngClass]="{'upgrade': toUpgrade}">
                                            גירסת נתיב במחשב זה {{ nativVersion }}
                                        </div>
                                        <button *ngIf="toUpgrade" (click)="updateVersion()"
                                            class="btn-nativ upgrade">{{'home.loggedin.version.upgrade-btn' | translate }}</button>
                                        <div *ngIf="updatedVersion" class="version-div">
                                            גירסה עדכנית!
                                        </div>
                                    </div> -->
                    <div class="buttons responsive-div row w-100">
                        <div *ngIf="showVideoButton" class="btn-div col-6" (click)="navigateToVideo();">
                            <div class="justify-content-center"><img
                                    src="../../../assets/images/home/new/video-i-2.png" /></div>
                            <div class="justify-content-center"> <a class="btn-nativ video-link">{{
                                    'home.loggedin.video'| translate }}</a></div>
                        </div>
                        <div class="btn-div col-6" pageScroll href="#section-5-before" (click)="saveUserAction(1);">
                            <div class="justify-content-center"> <img
                                    src="../../../assets/images/home/new/apps-i-2.png" /></div>
                            <div class="justify-content-center"><a class="btn-nativ apps-btn">{{ 'home.apps.shortcut' |
                                    translate }}
                                </a></div>
                        </div>

                    </div>
                </div>
                <div class="img-div row" [ngStyle]="{'background-image': 'url(' + bgURL + ')'}">
                    <div class="buttons-div col-6 text-center">
                        <div *ngIf="displayOmer" class="alerts-div server-alert omer-div">
                            <div class="alert-text">
                                <div>
                                    <div class="omer-data">
                                        <span>{{omerDate.days}}</span>
                                        <span>{{omerDate.weeks}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="displayMironLink" class="alerts-div server-alert omer-div"
                            style="margin-top: 236px;">
                            <div class="alert-text">
                                <div style="font-size: 28px;"> לצפיה בשידור חי מההדלקה במירון <a href="{{mironUrl}}"
                                        target="_blank">לחץ
                                        כאן</a> </div>
                            </div>
                        </div>

                        <div *ngIf="alertText && isShowServerAlert" class="alerts-div server-alert">
                            <div class="alert-text">
                                <div [innerHTML]="alertText">

                                    <!-- {{alertText}} -->
                                </div>
                                <button *ngIf="displayChatBtn" class="btn-nativ" (click)="showChat()">אני רוצה לדבר
                                    איתכם
                                    בצ'אט</button>
                                <div class="row justify-content-center">
                                    <button type="button" class="close server" (click)="closeServerAlert()"
                                        aria-label="Close">
                                        <span aria-hidden="true">סגור חלון</span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="isShowAlert && !isShowServerAlert" class="alerts-div"
                            [ngClass]="{'no-alert': alertText == '' || !isShowAlert}">
                            <div class="alert-content" *ngIf='isShowAlert'>
                                <div class="header-div">
                                    <div class="row btn-div">
                                        <button type="button" class="close" (click)="closeAlert()" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <h6 class="new">חדש!</h6>
                                    <div class="row">
                                        <div class="col-8">
                                            <h4 class="">כבר התקדמת לגירסה החדשה של נתיב?</h4>
                                        </div>
                                        <div class="col-4"><img
                                                src="../../../assets/images/home/new/alert-header-img.png" /></div>
                                    </div>
                                </div>
                                <br />
                                <div class="content">
                                    <div class="bold"> עידכון הגירסה החדש של נתיב מציע לכם ללא עלות:</div> <br />
                                    <i class="fa fa-check"></i>
                                    סינון תמונות חכם בטכנולוגיית בינה מלאכותית מתקדמת<br />
                                    <i class="fa fa-check"></i>
                                    סינון תוצאות חיפוש גוגל בזמן אמת
                                </div>
                                <div class="row submit-btn-div">
                                    <div class="col-12 text-center">
                                        <button class="btn-nativ" (click)="updateVersion()">שדרגו אותי
                                            בחינם</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="site-actions-content d-flex align-items-center col-6">
                        <div class="site-actions-div mx-auto">
                            <div class="sites-div div-1" routerLink="/add-sites"
                                (click)="saveUserAction(2)">
                                <img *ngIf='this.translateService.currentLang=="he"' class="sites-icon add"
                                    [src]="getSiteIconNew('2')" />
                                <img *ngIf='this.translateService.currentLang=="en"' class="sites-icon"
                                    src="../../../assets/images/sites-icon2-en.png" />
                                <div class="site-div text-right">
                                    <a [class.disabled]="disabledAddSites" class="btn-nativ">{{
                                        'home.loggedin.add-sites.header' |
                                        translate }}</a>
                                    <p class="text-right">
                                        {{ 'home.loggedin.add-sites.text' | translate }}
                                    </p>
                                </div>
                            </div>
                            <div class="sites-div div-2" (click)="navigateToMySites()">
                                <img *ngIf='this.translateService.currentLang=="he"' class="sites-icon"
                                    [src]="getSiteIconNew('1')" />
                                <img *ngIf='this.translateService.currentLang=="en"' class="sites-icon"
                                    src="../../../assets/images/sites-icon1-en.png" />
                                <div class="site-div text-right">
                                    <a class="btn-nativ">{{ 'home.loggedin.user-sites.header' |
                                        translate }}</a>
                                    <p class="text-right">
                                        {{ 'home.loggedin.user-sites.text' | translate }}
                                    </p>
                                </div>
                            </div>
                            <div class="sites-div div-3" routerLink="/remove-sites"
                                (click)="saveUserAction(3)">
                                <img *ngIf='this.translateService.currentLang=="he"' class="sites-icon"
                                    [src]="getSiteIconNew('3')" />
                                <img *ngIf='this.translateService.currentLang=="en"' class="sites-icon"
                                    src="../../../assets/images/sites-icon3-en.png" />
                                <div class="site-div text-right">
                                    <a class="btn-nativ">{{
                                        'home.loggedin.remove-sites.header' |
                                        translate }}</a>
                                    <p class="text-right">
                                        {{ 'home.loggedin.remove-sites.text' | translate }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="showAdvs" class="col-md-2 text-center left-adv ext-adv-div"
                (click)="navigateToAdvSite(advsData[0].link,'left')">
                <div class="col-md-10 text-center mx-auto img-adv-div">
                    <img class="adv-img" [src]="advsData[1].src" />
                </div>
                <!-- <iframe scrolling="no" class="" frameBorder="0" class="adv-iframe"
                    src="../../../assets/images/advs/ezer-mezion/ezer_mezion180x520.html"></iframe>
                <span style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 10;"
                    (click)="navigateToAdvSite(advsData[1].link,'left')"></span> -->
            </div>
        </div>
        <div *ngIf="displayInSmallScreen" class="user-div responsive-div">
            <div class="details-div text-center">
                <div class="buttons responsive-div">
                    <div *ngIf="showVideoButton" class="btn-div" (click)="navigateToVideo();">
                        <img src="../../../assets/images/home/new/video-i-2.png" />
                        <a class="btn-nativ video-link">{{
                            'home.loggedin.video'| translate }}</a>
                    </div>
                    <div class="btn-div" pageScroll href="#section-5-before" (click)="saveUserAction(1);">
                        <img src="../../../assets/images/home/new/apps-i-2.png" />
                        <a class="btn-nativ apps-btn">{{ 'home.apps.shortcut' |
                            translate }}
                        </a>
                    </div>

                </div>
                <div class="user-data">
                    {{ 'home.loggedin.header' | translate }}
                    <div class="user-details">
                        {{authenticationService.userData.firstname}} {{authenticationService.userData.lastname}}
                        <br />
                        {{ 'form.username' | translate }}: {{authenticationService.userData.username}}
                    </div>
                    <div *ngIf="noNativVersion && !userServer" class="user-data text-center user-div login"
                        (click)="nativLogin();">
                        <a class="btn-nativ pending-docs no-nativ">
                            אין משתמש נתיב מחובר <br />
                            <div class="bold"> התחבר כעת</div>
                        </a>
                    </div>
                    <div *ngIf="noNativVersion && userServer" class="user-data text-center user-div login">
                        <a class="btn-nativ pending-docs no-nativ-server">
                            שימו לב, תוכנת נתיב אינה מותקנת במחשב זה
                            והוא לא יהיה מסונן מחוץ לרשת זו.
                        </a>
                    </div>
                    <a *ngIf="!noNativVersion && !refreshing" class="btn-nativ refresh" (click)="refreshNativ()">{{
                        'global.refresh' |
                        translate }}</a>
                    <!-- <button *ngIf="!noNativVersion && refreshing" class="btn-nativ refresh">{{ 'global.refresh-now' |
                        translate
                        }}...</button> -->
                    <button *ngIf="!noNativVersion && refreshing" class="btn-nativ refresh refreshing">
                        <div>{{ 'global.refresh-now' | translate }}</div>
                        <!-- <ngx-spinner *ngIf="!noNativVersion && refreshing"
                            bdColor="rgba(234,230,230,0.63)" size="small" color="#fff" type="square-jelly-box"
                            [fullScreen]="false" template=" <img src='../../../assets/images/new/nativ-gif-2.gif' /> ">
                        </ngx-spinner> -->
                        <img src='../../../assets/images/new/nativ-gif-2.gif' />
                    </button>
                    <div *ngIf="pendingMsgsCount > 0" class="pending-docs-div">
                        <div class="col-md-12" *ngFor="let doc of pendingDocs;">
                            <button class="btn-nativ pending-docs pending-btn"><span class="msg-cnt">1</span>
                                <p (click)="navigateToDoc(doc.docType,doc.docId,doc.url)">{{
                                    'home.loggedin.pending-docs.subject-new' | translate }}: {{doc.title}}</p>
                                <a (click)="closeDoc(doc.docId)">מחק הודעה</a>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="version" *ngIf="isUserLoggedIn && !noNativVersion">
                    <div class="version-div" [ngClass]="{'upgrade': toUpgrade}">
                        גירסת נתיב במחשב זה {{ nativVersion }}
                    </div>
                    <div *ngIf="updatedVersion" class="version-div">
                        גירסה עדכנית!
                    </div>
                    <button *ngIf="toUpgrade" (click)="updateVersion()"
                        class="btn-nativ upgrade">{{'home.loggedin.version.upgrade-btn' | translate }}</button>
                </div>

            </div>
            <div class="img-div row" [ngStyle]="{'background-image': 'url(' + bgURL + ')'}">
                <div class="buttons-div col-6 text-center">
                    <div *ngIf="alertText && isShowServerAlert" class="alerts-div server-alert">
                        <div class="alert-text">
                            <div [innerHTML]="alertText">
                                <!-- {{alertText}} -->
                            </div>
                            <div class="row justify-content-center">
                                <button type="button" class="close server" (click)="closeServerAlert()"
                                    aria-label="Close">
                                    <span aria-hidden="true">סגור חלון</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isShowAlert && !isShowServerAlert" class="alerts-div"
                        [ngClass]="{'no-alert': alertText == '' || !isShowAlert}">
                        <div class="alert-content" *ngIf='alertText != "" && isShowAlert'>
                            <div class="header-div">
                                <div class="row btn-div">
                                    <button type="button" class="close" (click)="closeAlert()" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <h6 class="new">חדש!</h6>
                                <div class="row">
                                    <div class="col-8">
                                        <h4 class="">כבר התקדמת לגירסה החדשה של נתיב?</h4>
                                    </div>
                                    <div class="col-4"><img
                                            src="../../../assets/images/home/new/alert-header-img.png" /></div>
                                </div>
                            </div>
                            <br />
                            <div class="content">
                                <div class="bold"> עידכון הגירסה החדש של נתיב מציע לכם ללא עלות:</div> <br />
                                <i class="fa fa-check"></i>
                                סינון תמונות חכם בטכנולוגיית בינה מלאכותית מתקדמת<br />
                                <i class="fa fa-check"></i>
                                סינון תוצאות חיפוש גוגל בזמן אמת
                            </div>
                            <div class="row submit-btn-div">
                                <div class="col-12 text-center">
                                    <button class="btn-nativ" (click)="updateVersion()">שדרגו אותי בחינם</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="site-actions-div col-6">
                    <div class="sites-div div-1" routerLink="/add-sites" (click)="saveUserAction(2)">
                        <img *ngIf='this.translateService.currentLang=="he"' class="sites-icon add"
                            [src]="getSiteIconNew('2')" />
                        <img *ngIf='this.translateService.currentLang=="en"' class="sites-icon"
                            src="../../../assets/images/sites-icon2-en.png" />
                        <div class="site-div text-right">
                            <button [disabled]="disabledAddSites" class="btn-nativ">{{
                                'home.loggedin.add-sites.header' |
                                translate }}</button>
                            <p class="text-right">
                                {{ 'home.loggedin.add-sites.text' | translate }}
                            </p>
                        </div>
                    </div>
                    <div class="sites-div div-2" (click)="navigateToMySites()">
                        <img *ngIf='this.translateService.currentLang=="he"' class="sites-icon"
                            [src]="getSiteIconNew('1')" />
                        <img *ngIf='this.translateService.currentLang=="en"' class="sites-icon"
                            src="../../../assets/images/sites-icon1-en.png" />
                        <div class="site-div text-right">
                            <button class="btn-nativ">{{ 'home.loggedin.user-sites.header' |
                                translate }}</button>
                            <p class="text-right">
                                {{ 'home.loggedin.user-sites.text' | translate }}
                            </p>
                        </div>
                    </div>
                    <div class="sites-div div-3" routerLink="/remove-sites" (click)="saveUserAction(3)">
                        <img *ngIf='this.translateService.currentLang=="he"' class="sites-icon"
                            [src]="getSiteIconNew('3')" />
                        <img *ngIf='this.translateService.currentLang=="en"' class="sites-icon"
                            src="../../../assets/images/sites-icon3-en.png" />
                        <div class="site-div text-right">
                            <button class="btn-nativ">{{
                                'home.loggedin.remove-sites.header' |
                                translate }}</button>
                            <p class="text-right">
                                {{ 'home.loggedin.remove-sites.text' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-2"></div>
        <div class="search-sites col-8">
            <!-- <div class="search-images" id="search-images-1">
            <img [src]="getSearchBanner('1')" />
            <img [src]="getSearchBanner('2')" />
        </div> -->
            <div class="search-images" id="search-images-2">
                <img [src]="getSearchBanner('3')" />
                <!-- <img src="../../../assets/images/search-banner-4.png" /> -->
            </div>
            <div class="search-div text-center">
                <h1 class="search-header"> {{ 'home.loggedin.search-sites.header' | translate }}</h1>
                <form [formGroup]="searchSiteForm">
                    <div class="search-icon pointer" (click)="searchSites()">
                        <i class="fas fa-search"></i>

                    </div>
                    <input formControlName="search" type="text" [(ngModel)]="searchSitesInput"
                        (keyup.enter)="searchSites()" name="search" id="search" autocomplete="on" />
                </form>
            </div>
        </div>
    </div>
    <div class="row responsive-div">
        <!-- <div *ngIf="!showSites && !loading" class="col-1"></div> -->
        <div *ngIf="!showSites && !loading" class="categories-div col-8 responsive-div">
            <h2 class="text-center categories-header">{{ 'home.loggedin.search-sites.categories-header' | translate }}
            </h2>
            <table id="categories-table" class="table table-striped text-right">
                <tbody *ngIf="!mobileCategories">
                    <tr *ngFor="let category of categories;">
                        <td class="first-td text-right">
                            <a id="{{category.id1}}" (click)="getSites(category.id1,category.name1)">{{ category.name1
                                }}</a>
                        </td>
                        <td class="thin-td">
                            <a id="{{category.id2}}" (click)="getSites(category.id2,category.name2)">{{ category.name2
                                }}</a>
                        </td>
                        <td class="thin-td">
                            <a id="{{category.id3}}" (click)="getSites(category.id3,category.name3)">{{ category.name3
                                }}</a>
                        </td>
                        <td>
                            <a id="{{category.id4}}" (click)="getSites(category.id4,category.name4)">{{ category.name4
                                }}</a>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="mobileCategories">
                    <tr *ngFor="let category of mCategories;">
                        <td class="first-td text-right">
                            <a id="{{category.id1}}" (click)="getSites(category.id1,category.name1)">{{ category.name1
                                }}</a>
                        </td>
                        <td>
                            <a id="{{category.id2}}" (click)="getSites(category.id2,category.name2)">{{ category.name2
                                }}</a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- <div *ngIf="!showSites && !loading" class="col-1"></div> -->
    </div>
    <div *ngIf="showSites" class="sites-div row justify-content-center align-items-center">
        <div *ngIf="loading" id="loading" class="d-flex justify-content-center">
            <div class="spinner-border my-auto" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div *ngIf="!loading" class="col-md-6">
            <div class="d-flex justify-content-center p-4">
                <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
                    (pageChange)="refreshSites()" [boundaryLinks]="false" [maxSize]="5" data-toggle="tooltip">
                </ngb-pagination>
            </div>
            <div class="col-md-12 text-center">{{ 'home.loggedin.categories.found' | translate: {'num-sites':
                sitesLength } }} {{sites.length}} :
            </div>

            <table class="table table-striped text-center">
                <thead>
                    <tr>
                        <th scope="col" class="name">{{ 'home.loggedin.search-sites.table.name' | translate }}</th>
                        <th scope="col" class="category">{{ 'home.loggedin.search-sites.table.category' | translate
                            }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let site of sitesData;">
                        <td class="name"><a href="{{site.url}}" target="_blank">{{site.desc != "" ? site.desc :
                                site.url}}</a></td>
                        <td class="category pointer"><a
                                (click)="getSites(site.categoryId,site.categoryName)">{{site.categoryName}}</a>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="d-flex justify-content-center p-4">
                <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
                    (pageChange)="refreshSites()" [boundaryLinks]="false" [maxSize]="5">
                </ngb-pagination>
            </div>
            <div class="col-md-12 text-center">
                <button class="btn-nativ" (click)="showCategories()">{{ 'home.loggedin.categories.btn' | translate
                    }}</button>
            </div>
        </div>
    </div>
    <div id="section-5-before"></div>
</div>
<div id="section-5" class="text-center" data-aos="fade-up" data-aos-once="true" data-aos-duration="500"
    data-aos-mirror="true">
    <app-daily-apps></app-daily-apps>
</div>
<app-bottom-nav></app-bottom-nav>

<ng-template #modal let-modal class="modal alert-modal">
    <div class="modal-header">
        <div class="row">
            <div class="col-md-1 close-modal-header">
                <button type="button" class="close" (click)="close(modal)" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
    </div>

    <div class="modal-body text-center">
        למה כדאי לשדרג גרסה?<br />
        יותר חדשני: פיתחנו טכנולוגיה מתקדמת<br />
        לזיהוי תמונות המבוססת על בינה מלאכותית<br />
        כדי להתאים עבורכם סינון מדויק ואיכותי יותר.<br />
        תוכלו לקבל את השדרוג ללא תוספות מחיר.<br />
        עליכם רק לוודא שאתם בגירסה המעודכנת שלנו<br />
        ולפנות אלינו טלפונית ע''מ לעבור לסינון החכם.<br />
        <br />
        יותר בטוח: עדכון לתעודות אבטחה<br />
        וטיפול בבעיות אבטחה.<br />
        יותר עדכני: הגירסאות החדשות של נתיב מותאמות<br />
        למערכות הפעלה מתקדמות דוגמת windows 11<br />
        <br />
        יותר יעיל: כלי העידכון מופעל פעם אחת<br />
        לאחר מכן ימשיך לעשות לכם עידכונים אוטומטיים<br />
        בלי צורך בפעולה כלשהי.
    </div>
</ng-template>
<!--manager password-->
<ng-template #passModal let-modal class="modal pass-modal manager-pass-mysite">
    <div class="modal-header">
        <div class="row">
            <div class="col-md-1 close-modal-header">
                <button type="button" class="close" (click)="closeManager(modal)" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 dep-modal-header profile text-right">
                <div class="row">
                    <div class="col-3 pl-0 text-left">
                    </div>
                    <div class="col-9 pr-0">
                        <div class="not-mobile">{{ 'profile.password-modal.header' | translate }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-body">
        <form [formGroup]="syncPassForm" class="create-form text-center"
            (ngSubmit)="onSubmitPass(syncPassForm.value,modal)">
            <div class="row justify-content-center">
                <div class="col-md-10 input-div text-right">
                    <div class="form-group">
                        <label for="password">{{ 'profile.password-modal.input' | translate }}</label>
                        <div class="row">
                            <div class="row">
                                <input (focus)="onFocus()" formControlName="password"
                                    [type]="isPasswordVisible ? 'text' : 'password'" class="col-10 form-control"
                                    id="password" [ngClass]="{ 'is-invalid': managerSubmitted && fp.password.errors }">
                                <i [ngClass]="isPasswordVisible ? 'fa fa-eye' : 'fa fa-eye-slash'"
                                    class="col-2 eye-slash" (click)="togglePasswordVisibility()"></i>
                            </div>
                            <div *ngIf="managerSubmitted && fp.password.errors" class="invalid-feedback">
                                <div *ngIf="fp.password.errors.required">{{ 'form.validation.emptyPassword' |
                                    translate
                                    }} </div>
                                <div *ngIf="fp.password.errors && invalidPass">{{ 'form.validation.invalidPassword'
                                    |
                                    translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer justify-content-center">
                <button [disabled]="!enableSubmit" type="submit" class="btn-nativ form-btn">{{ 'global.enter'|
                    translate
                    }}</button>
            </div>
        </form>
    </div>
</ng-template>