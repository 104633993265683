import { Component, OnInit, Input, Output, EventEmitter, TemplateRef, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import * as io from "socket.io-client"
import { ApiService } from '../../services/api/api.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthenticationGuardService } from 'src/app/authentication-guard.service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import 'moment-timezone';


declare let window: any;
const storage = window.localStorage;

@Component({
  selector: 'app-chat-inbox',
  templateUrl: './chat-inbox.component.html',
  styleUrls: ['./chat-inbox.component.scss']
})
export class ChatInboxComponent implements OnInit {
  @ViewChild('modalMoney') public modalMoney: TemplateRef<any>;
  @ViewChild('modalVerify') public modalVerify: TemplateRef<any>;
  @ViewChild('myAudio') myAudio: ElementRef;

  @Input() isShow = false;
  @Output() isShowChatEvent = new EventEmitter<boolean>();
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;

  linkForRecord = "enativ.com/102"

  socket;
  message;
  userText = "";
  nativText = ""
  messages = []
  nativImageSrc = "../../../assets/images/chat/nativ-i-2.png";
  userImgSrc = "../../../assets/images/chat/user-i.png";
  showButtons = false;
  transferEmployee = false;
  startConversation = true;
  showInput = false;
  techOpeneHour = { hour: 10, minute: 0 };
  techCloseHour = { hour: 16, minute: 30 }
  isUserLoggedIn = false;
  username = "";
  writeName = ""
  userId = ""
  inetrvalId: any;

  noReplyFromEmployee: boolean;
  loginLink = 'https://bo.enativ.com/login'
  loginSoftware = false;
  protocol = window.location.protocol;

  contactForm: any;
  password = ""
  enableSubmit = true;
  submitted = false;
  startText = ""
  startTexts = {}
  isKey = { "username": false, "password": false }
  forgotForm: any;
  forgotSubmitted = false
  authenticated = true;
  authenticatedError = "";
  chatProcess: any;
  chatProcNow = { "start": false, "robotOrOtomat": false, "userDetails": false, "recognizeUserFromCache": false, "gviaProb": false, "vip": false, "helpOptions": false, "techSupport": false, "sites": false, "HCH": false, "notRecognizeUserFromCache": false, "chatProcNow": false, "addNewLicence": false }
  helpOptions = false
  promote = true;
  notPromote = false;
  oldVersion = false;
  Did1vipOr3ResetThisMonth = false;
  resetLicence = true;
  displayUpgradeVersion = true;
  noNativVersion = false;
  updatedVersion = true
  nativVersion = ""
  hasSim = true
  userData
  sticksData
  ntloginfirst = true
  ntlogin
  vipUser = false
  error = "error";

  showModal = false;
  modalHeader: string = "מחלקת גביה";
  modalName = '';
  contactFormMoney: any;
  emailFormControl: any;
  urlFormControl: any;
  phoneFormControl: any;
  submittedMoney = false;
  contacted = true;
  contactedError = "";
  depNumber = 0;
  dep = "";
  validPhoneNumber = true;
  enableSubmitMoney = true;
  priority = 2;
  userContent = "";
  urlParams = {};
  buyContent = ""
  dpt = 0;
  emptyPhoneNumber = false;
  fullname = ''
  fullnameSales = ''
  emailSales = ''
  phoneSales = ''
  phoneNumber = ''
  email = ''
  curModal: any;
  hasBiggerGiga = false
  url = ""
  openSite = true
  userSendMessageFirst = true
  chatId
  isThing = false
  notHome = false
  errorURL: string = ""
  checkURL: boolean = true
  connectSentence = ""

  formVerify: any
  docId = ""
  sentCode: string;
  doConfirm = false
  startChat: boolean;
  inputStep: any;
  existEmployees = false
  showInputUrl: boolean;
  showInputPass: boolean;
  currentStep: string;
  checkUrlCount: number = 0;
  passwordInput: string = ""
  checkPassCount: any = 0;
  showInputNtLogin: boolean;
  ntloginSaved: any;
  checkSitesCode: any = false;
  showInputSitesCode: boolean = false;
  sitesCode
  confirmOverQuote: any = false;
  showInputMail: boolean = false;
  mail
  details: Object;
  userDataDetails: any;
  userDataForSerials: any;
  serialsData: any[];
  serialsFilterData: any[];
  computerNames: { id: number, computerName: string }[] = [];
  computerLastLogins: { id: number, computerLastLogin: string }[] = [];
  enativVersions: { id: number, enativVersion: string }[] = [];
  logins: { id: number, login: string }[] = [];
  computerNameMinDate: string;
  checkManagerPassCount: number = 0;
  managerPassword: any;
  showInputManagerPass: boolean = false;
  currentStepVerifyPhone: string;
  nextStepVerifyPhone: any;
  stepClickVerifyPhone: string;
  typeProb: any;
  showInputPhone: boolean;
  showInputNumLicense: boolean
  phone: any;
  freeText: string = "";
  showInputFreeText: boolean;
  urlForContact: string;
  idContact: any;
  lastSite: any;
  siteNotWork: boolean;
  versionMinDate: string;
  formattedMinDate: string;
  modalSrc: string;
  mailOnly: boolean = false;
  currentTime: string = '';
  fullCurrentTime: string = '';
  hours: string;
  minutes: string;
  seconds: string;
  countOfPromoteDid: any;
  countOfPromoteHave: number;
  datePipe = new DatePipe('en-US');
  checklist1: { id: string; value: string; isSelected: boolean; }[];
  checklist2: { id: string; value: string; isSelected: boolean; }[];
  checklist3: { id: string; value: string; isSelected: boolean; }[];
  question1Val: any;
  question2Val: any;
  question3Val: any;
  feedbackForm: FormGroup;
  phoneCallId: any;
  feedbackType: any;
  nextStep: string;
  isFromSiteNotWork: boolean = false;
  userBlockSite: boolean = false;
  currentHour: number;
  currentMinute: number;
  currentSecond: number;
  currentDay: number;
  currentDate: string;
  update: moment.Moment;
  holding = false;
  sendMessageAfter20m: boolean;
  numOfLicenses: any = 0;
  startLogout: boolean;
  disconnect: boolean;
  waitMessageIntervalId: any;
  // waitEmployeeMessages = [
  //   'אנו מודעים לכך שאת/ה ממתין/ה,<br>ואנו עושים כמיטב יכולתנו כדי לתת לך מענה מהיר',
  //   'הפנייה שלך חשובה לנו!<br>ברגע שנסיים עם הלקוח הנוכחי,<br>נטפל בפנייתך במהירות',
  //   'אנו מעריכים את זמנך!<br>צוות השירות שלנו עסוק כרגע,<br>אך נענה לך ברגע שיתפנה נציג.<br>תודה על ההבנה!',
  //   'תודה על סבלנותך,<br>נציג זמין יחזור אליך תוך מספר דקות.',
  //   'רגע אחד, בבקשה,<br>אני איתך מיד',
  //   'אנו מעריכים את סבלנותך.<br>נחזור אליך ברגע שנציג יתפנה.',
  //   'קיבלנו את פנייתכם והיא תטופל בהקדם האפשרי.<br>תודה על ההבנה.',
  //   'אנו רואים את פנייתכם ונחזור אליכם בהקדם האפשרי.<br>תודה על ההמתנה.',
  //   'נודה לכם על המתנה נוספת ונתפנה אליכם בהקדם האפשרי'
  // ]
  waitEmployeeMessages = 'הפנייה שלך חשובה לנו!<br>ברגע שנסיים עם הלקוח הנוכחי,<br>נטפל בפנייתך במהירות'
  waitEmployeeMessagesIndex: number = 0
  //////V
  constructor(private apiService: ApiService,
    private router: Router,
    private formBuilder: FormBuilder,
    public authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private authGuardService: AuthenticationGuardService,
    private http: HttpClient) {
    this.authenticationService.authenticated$.subscribe(value => {
      this.isUserLoggedIn = value;
      if (this.isUserLoggedIn) {
        this.userId = this.authenticationService.userData.id
        this.username = this.authenticationService.userData.login
        this.password = this.authenticationService.userData.password
      }

    })
    this.contactForm = this.formBuilder.group({
      username: '',
      password: ''
    });
    this.forgotForm = this.formBuilder.group({
      username: ''
    });
    this.formVerify = this.formBuilder.group({
      function: ''
    });

    this.contactFormMoney = new FormGroup({
      fullname: new FormControl('', Validators.required),
      username: new FormControl(),
      // phonenumber: new FormControl('', [Validators.required, Validators.pattern("^(\\+\\d{1,3}( )?)?((\\(\\d{3}\\))|\\d{3})[- .]?\\d{3}[- .]?\\d{3}$")]),
      phonenumber: new FormControl('', [Validators.required, Validators.pattern(/^0(([23489]{1}\d{7})|[5]{1}\d{8}|(([7]\d{1,2}\-\d{7})|([7]\d{8})))$/)]),
      email: new FormControl('', [Validators.required, Validators.email, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[A-Za-z]{2,4}$")]),
      content: new FormControl('', [Validators.required, Validators.minLength(6)])
    });
    this.emailFormControl = new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[A-Za-z]{2,4}$")
    ]);
    this.urlFormControl = new FormControl('', [
      Validators.required,
      Validators.pattern(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\. _~:/?#[\]@!\$&'\(\)\*\+,;=.%]+$/)
      // Validators.pattern('^([a-zA-Z]+:\\/\\/)?' + // protocol
      //   '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      //   '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
      //   '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      //   '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      //   '(\\#[-a-z\\d_]*)?$', // fragment locator
      // )
    ]);

    this.phoneFormControl = new FormControl('', [
      Validators.required,
      Validators.pattern(/^0(([23489]{1}\d{7})|[5]{1}\d{8}|(([7]\d{1,2}\-\d{7})|([7]\d{8})))$/)
    ]);
    this.feedbackForm = this.formBuilder.group({
      question1: '',
      question2: '',
      question3: '',
      comments: ''
    });
    this.checklist1 = [
      { id: "5", value: "5", isSelected: false },
      { id: "4", value: "4", isSelected: false },
      { id: "3", value: "3", isSelected: false },
      { id: "2", value: "2", isSelected: false },
      { id: "1", value: "1", isSelected: false },
    ];
    this.checklist2 = [
      { id: "5", value: "5", isSelected: false },
      { id: "4", value: "4", isSelected: false },
      { id: "3", value: "3", isSelected: false },
      { id: "2", value: "2", isSelected: false },
      { id: "1", value: "1", isSelected: false },
    ];
    this.checklist3 = [
      { id: "5", value: "5", isSelected: false },
      { id: "4", value: "4", isSelected: false },
      { id: "3", value: "3", isSelected: false },
      { id: "2", value: "2", isSelected: false },
      { id: "1", value: "1", isSelected: false },
    ];
  }
  //////V
  saveChatOpen() {
    this.apiService.saveChatOpen(this.chatProcess["welcomeMessage"].message, this.userData.id, this.fullCurrentTime).subscribe(data => {
      this.chatId = data["d"]
      console.log(this.chatId, data, this.existEmployees)
    })
    this.startChat = true
    // if (this.existEmployees) {
    //   this.next("start", "robotOrOtomat")
    // }
    // else {
    // if (!this.loginSoftware)
    this.next("start", "welcomeMessage")
    // }
    if (this.userData && !this.userData.stick) {
      this.hasSim = false
    }
    if (this.userData && (this.userData.usertypeid.charAt(5) == 7 || this.userData.usertypeid.charAt(5) == 'C')) {
      this.mailOnly = true
    }
  }

  //////V
  ngOnInit() {
    if (this.isShow) {
      this.setupSocketConnection();

      // this.messages.push({ text: "על מנת שנוכל לעזור לך, אנא הכנס שם משתמש", imgSrc: this.nativImageSrc, type: "nativ" })
      // document.getElementById("message-id").innerHTML="x[0].innerHTML"
    }
    this.contactForm = new FormGroup({
      username: new FormControl('', [Validators.required, Validators.pattern(/\S{2,}/)]),
      password: new FormControl('', Validators.required),
    });//שליחת אימות קולי
    this.apiService.codeSent$.subscribe(value => {
      console.log(`codeSent value ${value}`)
      this.sentCode = value;
      // if (this.sentCode.startsWith("valid") && this.getVariable()) {
      if (this.sentCode.startsWith("valid")) {
        if (this.sentCode.startsWith("valid")) {
          this.next(this.currentStepVerifyPhone, this.nextStepVerifyPhone)
        }
        this.setVariable(false)
        this.sentCode = ""
      }
      // else if (this.sentCode.startsWith("exit")) {
      //   this.next(this.currentStep, '')
      // }
      this.sentCode = value;
    }, error => {
      this.isThing = false;
      this.next(this.currentStep, this.error)
      return;
    });

    this.feedbackForm = new FormGroup({
      question1: new FormControl('', Validators.required),
      question2: new FormControl('', Validators.required),
      question3: new FormControl('', Validators.required),
      comments: new FormControl()
    });

  }
  //V
  ngAfterViewInit() {
    this.http.get<any>('../../../assets/chatJson/chat.json').subscribe(data => {
      this.chatProcess = data;
      console.log(this.chatProcess)
      //user is not connected to software
      // if (!this.authenticationService.noNativSoftware && !this.authenticationService.loggedInNouser && this.authenticationService.userData.appNativVersion == "none") {
      //   this.loginSoftware = true;
      //   this.startChat = true
      // }
      // else 
      if (this.authenticationService.loggedInNouser) {
        this.loginSoftware = true;
        this.startChat = true
      }
      else if (this.authenticationService.userData) {
        console.log("before save chat open")
        this.userData = this.authenticationService.userData
        this.authenticationService.login(this.authenticationService.userData).subscribe(
          data => {
            this.userData = { ...this.authenticationService.userData, ...data["UserData"] }
            localStorage.setItem('nativUser', JSON.stringify(this.authenticationService.userData));
          },
          error => {
            console.log("home-logged-in:login error:" + JSON.stringify(error))
          }
        )
        this.updatedTime()
        this.saveChatOpen()
      }
      else {
        this.startLogout = true
        this.chatProcNow["start"] = true
      }
    });
  }

  private variableKey = 'myVariable';

  getVariable(): boolean {
    const value = storage.getItem(this.variableKey);
    return value ? JSON.parse(value) : false;
  }

  setVariable(value: boolean): void {
    storage.setItem(this.variableKey, JSON.stringify(value));
  }
  ///V
  toggleChat() {
    this.isShow = !this.isShow
    this.isShowChatEvent.emit(this.isShow);
    if (this.isShow) {
      this.transferEmployee = true
      //this.next("start", "robotOrOtomat")
      this.setupSocketConnection();
    }
    else {
      this.socket.disconnect();
      clearInterval(this.inetrvalId)
    }
  }
  ////V
  setupSocketConnection() {
    console.log("in setupSocketConnection")
    this.socket = io.connect();
    console.log(this.socket)
    this.socket.on('close-broadcast', () => {
      this.holding = false;
      this.showInput = false
      setTimeout(() => {
        this.next("employee", "isFeedback")
      }, 1500);
      this.setupSocketConnection()
      clearInterval(this.inetrvalId)
    });
    this.socket.on('hold-broadcast', () => {
      console.log("hold")
      this.holding = !this.holding;
      if (this.holding)//נציג שם על hold
      {
        // this.saveChatMessage("נציג בודק נתונים ושם את המשתמש בהמתנה", this.chatId, "robot", this.fullCurrentTime);
        this.update = moment().tz('Asia/Jerusalem').add(10, 'minutes');
      }
      else //נציג שחרר מ hold
      {
        // this.saveChatMessage("נציג שחרר את המשתמש מהמתנה", this.chatId, "robot", this.fullCurrentTime);
        this.update = moment().tz('Asia/Jerusalem');
      }
    });
    this.socket.on('message-broadcast', (data: string) => {
      if (data) {
        data = data.replace(/\n/g, '<br>');
        this.noReplyFromEmployee = false;
        this.holding = false;
        console.log("data from setupSocketConnection", data)
        this.updatedTime()
        clearInterval(this.inetrvalId)
        this.messages.push({ text: data, imgSrc: this.nativImageSrc, type: "nativ", time: this.currentTime })
        this.update = moment().tz('Asia/Jerusalem');
        const count = this.messages.length;
        const audio = new Audio();
        audio.src = "../../../assets/audio/get-message-chat.mp3";
        audio.load();
        audio.play();
        // const audio = this.myAudio.nativeElement;
        // audio.play();
        this.scrollToBottom()
        this.sendMessageAfter20m = false
        clearInterval(this.inetrvalId)
        //if user doesnt send message for 15 m we close the chat
        this.inetrvalId = setInterval(() => {
          const hour = this.update.hours()
          const minute = this.update.minutes()
          const nowHour = new Date().getHours()
          const nowMinute = new Date().getMinutes()
          console.log("in setInterval " + nowHour + ":" + nowMinute + " update: " + hour + ":" + minute);
          if ((nowHour == hour && nowMinute - 15 > minute) || (nowHour - 1 == hour && minute - 45 < nowMinute)) {
            console.log("chat close after 15 minutes")
            this.socket.disconnect()
            clearInterval(this.inetrvalId)
            this.toggleChat()
          }
          //console.log(sendMessageAfter20m,((nowHour == hour && nowMinute - 20 > minute) || (nowHour - 1 == hour && minute - 20 < nowMinute)))
          else if (!this.sendMessageAfter20m && ((nowHour == hour && nowMinute - 5 > minute) || (nowHour - 1 == hour && minute - 55 < nowMinute))) {
            console.log("5 minutes pass")
            this.sendMessageAfter20m = true
            this.sendMsg("nativ", "אנו ממתינים לתשובה, באם לא תענו בתוך 10 דק נסגור את הצאט.", this.chatId, "robot")
          }
        }, 60000)
        //}
      }
    });
  }

  //user send message to employee
  sendMessage() {
    // if (!this.transferEmployee) {
    //   this.next(this.inputStep, this.chatProcess.inputStep.nextStep)
    // }
    if (this.message && this.transferEmployee) {
      //this.update = new Date()
      clearInterval(this.inetrvalId)
      //this.writeName = this.username ? this.username : "unknown"
      this.socket.emit('message', this.username + ",@!;~" + this.connectSentence + ". הודעת הלקוח: " + this.message + ",@!;~" + this.userId + ",@!;~" + this.chatId);
      this.sendMsg("user", this.message, this.chatId, this.writeName)
      this.message = '';
      if (this.userSendMessageFirst) {
        this.noReplyFromEmployee = true
        this.userSendMessageFirst = false
        this.sendMsg("nativ", "אנו מחפשים עבורכם נציג פנוי, אנא המתינו...", this.chatId, "robot")
      }
      this.waitMessageIntervalId = setInterval(() => {
        if (!this.noReplyFromEmployee) {
          clearInterval(this.waitMessageIntervalId); // מפסיק את ה- setInterval
          return;
        }
        this.sendMsg("nativ", this.waitEmployeeMessages, this.chatId, "robot");
        clearInterval(this.waitMessageIntervalId); // מפסיק את ה- setInterval
      }, 120000);
    }
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }

  get f() {
    return this.contactForm.controls;
  }

  get fm() {
    return this.contactFormMoney.controls;
  }

  get fp() {
    return this.forgotForm.controls;
  }

  //on submit when open the chat
  onSubmit(formData: any) {
    console.log("I on Submit function")
    this.disconnect = false;
    this.submitted = true;
    this.enableSubmit = false;
    if (this.contactForm.invalid) {
      this.enableSubmit = true;
      return;
    }
    this.apiService.login(formData).subscribe(
      data => {
        try {
          if (data["data"] == "User does not exists!") {
            this.enableSubmit = true;
            this.authenticated = false;
            this.authenticatedError = 'שם משתמש או סיסמא שגויים';
            return
          }
          this.authenticationService.enterSite(data["data"]["UserData"]);
          this.userData = data["data"]["UserData"]
          this.authenticationService.login(this.authenticationService.userData).subscribe(
            data => {
              this.userData = { ...this.authenticationService.userData, ...data["UserData"] }
              localStorage.setItem('nativUser', JSON.stringify(this.authenticationService.userData));
            },
            error => {
              console.log("home-logged-in:login error:" + JSON.stringify(error))
            }
          )
          this.username = this.userData.login
          this.userId = this.userData.id
          console.log('success login', data)
          console.log("this.userData ", this.userData)
          // if (!this.authenticationService.userData || this.authenticationService.userData.login != this.userData.login) {
          //   this.enableSubmit = true;
          //   this.authenticated = false;
          //   this.authenticatedError = 'על מנת להתכתב בצאט, יש להתחבר לאתר עם השם משתמש איתו רוצים להתכתב'
          // }
          // else {
          this.startLogout = false
          this.saveChatOpen()
          // }
        }
        catch (error) {
          this.enableSubmit = true;
          console.log(error)
        }
      },
      error => {
        this.enableSubmit = true;
        this.authenticated = false;
        if (error.error && error.error.text && error.error.text === "User does not exists!") {
          this.authenticatedError = 'שם משתמש שגוי';
        }
        else {
          this.authenticatedError = 'שם משתמש או סיסמא שגויים'
        }
        console.log('error login', error)
      }
    )
  }

  isKeyStart(input) {
    this.isKey[input] = true
  }

  openModal(targetModal, typeModal = "restModal") {
    if (typeModal == "moneyModal") {
      this.depNumber = 5
      this.modalHeader = "מחלקת גביה"
      this.modalSrc = "../../../assets/images/contact/i-money.png"
    }
    if (typeModal == "techModal") {
      this.depNumber = 1
      this.modalHeader = "מחלקת תמיכה טכנית"
      this.modalSrc = "../../../assets/images/contact/i-tech-support.png"
    }
    const modal = this.modalService.open(targetModal, {
      centered: true,
      backdrop: true,
      size: 'lg'
    });
    if (typeModal == "moneyModal" || typeModal == "techModal") {
      modal.result.then(
        (result) => {
          console.log('Modal dismissed or closed:', result);
          // Handle the result when the modal is dismissed or closed
          if (this.userData.gviaproblem != 0) {
            this.next('openContactAcountApartment', 'final')
            return;
          }
          else {
            this.next('openContactAcountApartment', 'moreHelp')
            return;
          }

          // Perform your additional actions here
        },
        (reason) => {
          // Handle the reject reason if the modal is dismissed with a reason/error
          console.log('Modal dismissed with reason:', reason);
          if (reason == 0) {
            if (this.userData.gviaproblem != 0) {
              this.next('openContactAcountApartment', 'final')
              return;
            }
            else {
              this.next('openContactAcountApartment', 'moreHelp')
              return;
            }
          }

          // Perform your additional actions here
        });
    }
  }

  openModalForgetPass(targetModal) {
    const modal = this.modalService.open(targetModal, {
      centered: true,
      backdrop: true,
      size: 'md'
    });
  }

  close(modal, gvia = "") {
    modal.dismiss('GOOD')
    if (gvia)
      if (this.userData.gviaproblem != 0) {
        this.next('openContactAcountApartment', 'final')
        return;
      }
      else {
        this.next('openContactAcountApartment', 'moreHelp')
        return;
      }
  }

  onSubmitForgot(forgotData: any, modal) {
    modal.dismiss()
    this.forgotSubmitted = true;
    if (this.forgotForm.invalid) {
      return;
    }
    try {
      this.apiService.forgotPass(forgotData).subscribe(
        data => {
          if (data["d"] == "true") {
            Swal.fire("", "הסיסמא נשלחה לכתובת המייל הרשומה במערכת", 'success')
          }
          else {
            Swal.fire("", data["d"], 'warning')
          }
        },
        error => {
          console.log(error)
          this.isThing = false;
          this.next(this.currentStep, this.error)
          // Swal.fire("", "אירעה שגיאה בשליחת הבקשה", 'error')
          return;
        }
      )
    }
    catch (error) {
      console.log(error)
      this.isThing = false;
      this.next(this.currentStep, this.error)
      // Swal.fire("", "אירעה שגיאה בשליחת הבקשה", 'error')
      return;
    }
  }

  //the logic in chat when used robot
  async next(curStep: string, nextStep: string, stepClick: string = "") {
    console.log(this.userData)
    if (nextStep == 'welcomeMessage') {
      this.chatProcNow = { "start": false, "robotOrOtomat": false, "userDetails": false, "recognizeUserFromCache": false, "gviaProb": false, "vip": false, "helpOptions": false, "techSupport": false, "sites": false, "HCH": false, "notRecognizeUserFromCache": false, "chatProcNow": false, "addNewLicence": false }
      this.showInputUrl = false
      this.showInput = false;
      this.url = ""
      this.mail = ""
      this.checkURL = true
      this.openSite = true;
      this.showInputNtLogin = false
      this.ntlogin = ""
      this.showInputSitesCode = false
      this.sitesCode = ""
      this.checkSitesCode = false
      this.showInputMail = false
      this.showInputManagerPass = false
      this.managerPassword = ""
      this.checkManagerPassCount = 0;
      this.showInputPhone = false;
      this.showInputNumLicense = false;
      this.showInputFreeText = false
      this.freeText = ""
      this.checkPassCount = 0;
      this.checkUrlCount = 0;
      this.numOfLicenses = null;
    }
    this.currentStep = curStep;
    this.nextStep = nextStep
    this.writeName = this.username ? this.username : "unknown"
    // this.userData.appNativVersion=""
    // this.userData.lowVersion = true
    // this.userData.BlockActivity="2"
    // this.userData.quota = 0
    console.log("in next() function", this.chatProcess[nextStep])
    if (nextStep == "openSite") {
      console.log("in open begin")
      //בודק אם קיים 'מנע הוספת אתרים' ללקוח
      if (this.userData.noaddsites == "1") {
        this.existEmployees = await this.checkAgentAvailability()
        if (this.existEmployees) {
          this.connectSentence = "לקוח הועבר לנציג לאחר שניסה להוסיף אתר אך לא יכול כיוון שמוגדר לו מנע הוספת אתרים"
          nextStep = "noAddSiteExistEmployee"
          this.nextStep = nextStep
        }
        else {
          nextStep = "noAddSiteNoExistEmployee"
          this.nextStep = nextStep
        }
      }
      //אם לא - בודק אם מחובר לתוכנה
      else if (nextStep == "openSite" && this.userData.appNativVersion && this.userData.appNativVersion != "none") {
        console.log("in open site connect nativ")
        //אם מחובר - בודק האם קיים הגבלה על הוספת אתרים
        if (this.userData.lowVersion && this.userData.BlockActivity.includes("2")) {
          nextStep = "updateNativ"
          this.nextStep = nextStep
          // this.next(curStep, nextStep, stepClick)
          // return
        }
      }
      //this.userData.quota = 0

      //אם לא - בודק קוד להוספת אתרים
      if (nextStep == "openSite" && this.userData.AddSitesCode != null && this.userData.AddSitesCode != "" && !this.checkSitesCode) {
        console.log("in open site sites code")
        nextStep = "enterSitesCode"
        this.nextStep = nextStep
        // this.next(curStep, nextStep, stepClick)
        // return
      }
      //אם לא - בודק מכסת אתרים
      else if (nextStep == "openSite" && this.userData.quota < 1 && !this.confirmOverQuote) {
        console.log("in open site quote")
        nextStep = "overSiteQuota"
        this.nextStep = nextStep
        this.confirmOverQuote = true
        // this.next(curStep, nextStep, stepClick)
        // return
      }
      else {
        this.confirmOverQuote = false
        if (this.isFromSiteNotWork) {
          this.url = this.urlForContact
          if (this.userData.ReceiveMailMessage == "2") {
            this.addSite()
          }
          else {
            if (this.vipUser && this.userData.nmboflicence > 5) {
              this.next("checkVipUser", "enterMail")
            }
            else {
              this.next("checkVipUser", "whichMail")
            }
          }
          this.chatProcNow[curStep] = false
          return
        }
      }
    }
    if (this.chatProcess[nextStep] && this.chatProcess[nextStep].inputStep) {
      this.inputStep = nextStep
      switch (this.chatProcess[nextStep].inputStep) {
        case "url":
          this.showInputUrl = true
          break;
        case "ntlogin":
          this.showInputNtLogin = true
          break;
        case "sitesCode":
          this.showInputSitesCode = true
          break;
        case "mail":
          this.showInputMail = true
          break;
        case "managerPass":
          this.showInputManagerPass = true
          break;
        case "phone":
          this.showInputPhone = true
          break;
        case "freeText":
          this.showInputFreeText = true
          break;
        case "numOfLicenses":
          this.showInputNumLicense = true
          break;
        default:
          break;
      }
    }
    if (nextStep == "checkIfSiteWait") {
      nextStep = await this.hasUserWaitSite();
      this.nextStep = nextStep
      console.log("Next step:", nextStep);
    }
    if (nextStep == "tryToAddLicense") {
      this.sendMsg("nativ", "אני מוסיף כעת רשיון.<br> פעולה זו לוקחת מעט זמן. אנא התאזרו בסבלנות עד לסיום התהליך", this.chatId, "robot")
      this.isThing = true
      this.apiService.addLicenses(this.userData.login, this.numOfLicenses).subscribe(data => {
        this.isThing = false;
        this.numOfLicenses = null
        if (data)
          if (data["res"]) { //success-userid=44423-gemniid=5835
            const res = data["res"].split("-")
            const userid = res[1].split("=")[1]
            if (res[0] == "success" && userid !== "0") {
              this.next(nextStep, this.chatProcess[nextStep].nextStep.addLicenseSuccess)
              return
            }
            else {
              this.next(nextStep, this.chatProcess[nextStep].nextStep.gviaProblem)
              return
            }
          }
          else {
            this.next(nextStep, this.chatProcess[nextStep].nextStep.gviaProblem)
            return
          }
      },
        error => {
          this.isThing = false;
          this.numOfLicenses = null
          this.next(nextStep, this.chatProcess[nextStep].nextStep.gviaProblem)
          return
        }
      )
    }
    if (curStep == "gviaProblem") {
      this.apiService.contact("צאט באתר: הלקוח ביקש להוסיף רשיון אבל הפעולה נכשלה כי התשלום לא עבר", 5, this.userData.login, this.phone, this.email, this.userData.firstname, this.userData.lastname, 2).subscribe(
        data => {
          console.log('success contact', data)
        },
        error => {
          console.log('error contact', error)
        }
      )

    }
    if (nextStep == "moreHelp" || nextStep == "moreHelpNoEmployee") {
      this.checkSitesCode = false
      this.confirmOverQuote = false
    }
    if (nextStep == "feedback") {
      nextStep = this.transferEmployee ? this.chatProcess[nextStep].nextStep.employee : this.chatProcess[nextStep].nextStep.robot
      this.nextStep = nextStep
    }
    if (nextStep == "thankMsgFeedback") {
      this.feedbackType = this.transferEmployee ? 3 : 4
      this.apiService.addFeedbackChat(this.userData.id, this.question1Val, this.question2Val, this.question3Val, this.freeText ? this.freeText : "", this.feedbackType).subscribe(
        data => {
          console.log('success addFeedbackChat', data)
        },
        error => {
          console.log('error addFeedbackChat', error)
        }
      )
    }
    if (nextStep == "checkHaveSite") {
      nextStep = await this.hasUserSite();
      this.nextStep = nextStep
      console.log("Next step:", nextStep);
    }
    if (nextStep == "hasNoSite") {
      console.log("in has no site")
      if (this.userData.activelist != "רשימה אפורה") {
        nextStep = "whiteList"
        this.nextStep = nextStep
      }
      else {
        nextStep = "grayList"
        this.nextStep = nextStep
        //curStep = "grayList"
      }
    }
    if (nextStep == "checkUpdate") {
      this.authenticationService.shortNativLogin().subscribe(data => {
        if (data) {
          let lastVersion
          this.apiService.getLastNativVersion().subscribe(dataVersion => {
            lastVersion = dataVersion["res"]
          })
          //אם הגרסה מעודכנת
          if (data["NativVersion"] == lastVersion) {
            this.next(nextStep, this.chatProcess[nextStep].nextStep.success)
          }
          else {
            this.next(nextStep, this.chatProcess[nextStep].nextStep.notSuccess)
          }
        }
      })
    }
    if (nextStep == "openContactAcountApartment") {
      setTimeout(() => {
        this.openModal(this.modalMoney, "moneyModal")
      }, 3000);
    }
    if (nextStep == "waitOrOtomat") {
      this.addSite()
    }
    if (nextStep == "openContactProbSite") {
      let content = "צ'אט באתר: דיווח על תוכן בעייתי כתובת אתר:" + this.urlForContact + " סוג תוכן בעייתי: " + this.typeProb + " הערת הלקוח: " + this.freeText
      content += this.userBlockSite ? " בעקבות התקלה הלקוח חסם את האתר באישי עד שהתקלה תטופל" : ""
      const phone = this.phone ? this.phone : this.userData.MainPhone
      const email = this.email ? this.email : this.userData.email
      this.apiService.contact(content, 17, this.userData.login, phone, email, this.userData.firstname, this.userData.lastname, 2).subscribe(data => {
        if (data["PData"]) {
          this.idContact = data["PData"].pid
        }
      })
    }
    if (nextStep == "licenceDetailsInUserProfile" || nextStep == "navigateUserProfile") {
      this.router.navigate(['/user-profile']);
      // this.navigateUserProfile();
    }
    if (nextStep == "thisComputer") {
      if (this.userData.gviaproblem != 0) {
        nextStep = "acountErrorMessage"
        this.nextStep = nextStep
      }
      else if (this.userData.status > 1) {
        //nextStep = "techSupport"
        this.vipUser = true
      }
    }
    if (nextStep == "checkServerUser") {
      if (this.userData.activelist.includes("משרת")) {
        nextStep = "otomatRequestFromServer"
        this.nextStep = nextStep
      }
      else {
        nextStep = "otomatRequestNoFromServer"
        this.nextStep = nextStep
      }
    }
    if (nextStep == "sales") {
      this.startChat = true
    }
    if (nextStep == "checkLastSite") {
      if (!this.lastSite) {
        this.next(this.currentStep, this.chatProcess[nextStep].nextStep.error)
        return
      }
      setTimeout(() => {
        this.sendMsg("nativ", "הבקשה האחרונה היא על אתר: " + this.lastSite["URL"], this.chatId, "robot")
      }, 1500);
      setTimeout(() => {
        this.next(this.currentStep, this.chatProcess[nextStep].nextStep.success)
      }, 1500);
      return
    }
    if (nextStep == "resetSomeLicence") {
      this.isThing = true
      this.apiService.resetSomeLicence(this.userData.id).subscribe(data => {
        if (data) {
          this.isThing = false
          console.log("success reset some licence")
        }
      }, error => {
        this.isThing = false;
        this.next(this.currentStep, this.error)
        return
      })
    }
    if (nextStep == "addGigaVerifyUser" || nextStep == "verifyUserPhone") {
      if (!this.authenticationService.userData) {
        this.disconnect = true
      }
      else {
        this.currentStepVerifyPhone = nextStep
        if (this.numOfLicenses > 0) {//addLicenseValidationSuccess
          this.nextStepVerifyPhone = this.chatProcess[nextStep].nextStep.addLicenseValidationSuccess
        }
        else {//resetOneLicence
          this.nextStepVerifyPhone = this.chatProcess[nextStep].nextStep.resetOneLicence
        }
        this.formVerify.function = "chat"
        this.openModal(this.modalVerify)
        this.chatProcNow[curStep] = false
        return
      }
    }
    if (stepClick) {
      if (this.ntlogin) {
        this.sendMsg("user", this.ntlogin, this.chatId, this.writeName)
        //this.ntloginfirst = false
      }
      else {
        this.sendMsg("user", stepClick, this.chatId, this.writeName)
      }
    }
    //מעבר לנציג
    if (nextStep.startsWith("transfer")) {
      this.showInputNtLogin = false
      console.log(curStep, nextStep, this.chatProcess[curStep].connectMessage, this.chatProcess[nextStep].connectMessage)
      this.existEmployees = await this.checkAgentAvailability()
      if (!this.existEmployees) {
        if (this.chatProcess[curStep].noEmployeeMessage != undefined) {
          this.sendMsg("nativ", this.chatProcess[curStep].noEmployeeMessage, this.chatId, "robot")
        }
        nextStep = this.chatProcess[curStep].nextStepNoEmployee ? this.chatProcess[curStep].nextStepNoEmployee : "moreHelp"
        this.nextStep = nextStep
      }
      else {
        if (this.chatProcess[curStep].connectMessage != undefined) {
          if (this.numOfLicenses > 0) {
            this.connectSentence = this.chatProcess[curStep].connectMessage.notSucsessManagerPassAddLicence
            this.numOfLicenses = 0;
          }
          else if (this.chatProcess[curStep].connectMessage?.notSucsessManagerPassResetLicence) {
            this.connectSentence = this.chatProcess[curStep].connectMessage.notSucsessManagerPassResetLicence
          }
          else {
            this.connectSentence = this.chatProcess[curStep].connectMessage
          }
        }
        this.sendMsg("nativ", "ברוכים הבאים לצאט עם נציג! איך נוכל לעזור לכם?", this.chatId, "robot")
        this.transferEmployee = true
        this.chatProcNow[curStep] = false
        this.showInput = true
        return
      }
    }
    // if (nextStep == "addGigaVerifyUser") {
    //   this.formVerify.function = "chat"
    //   this.openModal(this.modalVerify)
    //   this.chatProcNow[curStep] = false
    //   return
    // }
    if (curStep == "makeRecord") {
      window.open(`${this.protocol}//download.enativ.com/snt.exe`);
      // setTimeout(() => {
      //   const protocol = window.location.protocol;
      //   window.open(`${protocol}//download.enativ.com/snt.exe`);
      // }, 5000);
    }
    if (nextStep == "openContactNotSucessUpdate") {
      this.apiService.contact("צאט באתר: לקוח ניסה לעדכן גירסה לאחר שניסה לבקש אתר אך ללא הצלחה", 1, this.userData.login, this.phone, this.email, this.userData.firstname, this.userData.lastname, 2).subscribe(
        data => {
          console.log('success open Contact Not Success Update', data)
        },
        error => {
          console.log('error openContactProbInstall', error)
        })
    }
    if (nextStep == "openContactProbInstall") {
      this.apiService.contact("צאט באתר: משתמש נתקל בשגיאה בהתקנה", 1, this.userData.login, this.userData.MainPhone, this.userData.email.split(',')[0], this.userData.firstname, this.userData.lastname, 2).subscribe(
        data => {
          console.log('success openContactProbInstall', data)
        },
        error => {
          console.log('error openContactProbInstall', error)
        }
      )
    }
    if (nextStep == "blockSite") {
      this.chatProcNow[curStep] = false
      const isBlock = await this.blockSite()
      if (!isBlock) {
        this.next(this.currentStep, this.error)
      }
    }
    if (nextStep == "otherComputerIsExistsEmp") {
      this.existEmployees = await this.checkAgentAvailability()
      if (this.existEmployees) {
        this.next(nextStep, this.chatProcess["otherComputerIsExistsEmp"].nextStep.existsEmployee)
      }
      else {
        this.next(nextStep, this.chatProcess["otherComputerIsExistsEmp"].nextStep.noExistsEmployee)
      }
    }
    if (nextStep == "openContactTechApartment") {
      setTimeout(() => {
        this.openModal(this.modalMoney, "techModal")
      }, 2000)
    }
    if (nextStep == "updateVersion") {

      this.chatProcNow[curStep] = false
      //ללא תוכנה   
      if (this.authenticationService.noNativSoftware) {
        this.next(nextStep, this.chatProcess[nextStep].nextStep.noNativSoftware)
        return
      }
      this.isUpdatedVersion().then(() => {
        //גרסא עדכנית
        if (this.updatedVersion) {
          this.next(nextStep, this.chatProcess[nextStep].nextStep.updatedVersion)
          return
        }
        //גרסא ישנה
        else {
          this.next(nextStep, this.chatProcess[nextStep].nextStep.oldVersion)
          return
        }
      })
        .catch(error => {
          this.isThing = false;
          this.next(this.currentStep, this.error)
          return
        });
    }
    if (nextStep == "downloadUpdate") {
      setTimeout(() => {
        const protocol = window.location.protocol;
        // const link = document.createElement('a');
        // link.href = `${protocol}//enativ.com/2`
        // link.click()
        window.open(`${protocol}//enativ.com/2`);
      }, 1500);
    }
    if (nextStep == "checkManagerPass") {
      if (this.userData.ManagerPassword != "") {
        this.next(nextStep, this.chatProcess["checkManagerPass"].nextStep.thereIsManagerPass)
      }
      else {
        setTimeout(() => {
          this.next(nextStep, this.chatProcess["checkManagerPass"].nextStep.thereIsNoManagerPass)
        }, 1500)
      }
    }
    if (nextStep == "thereIsNoManagerPass") {
      this.apiService.contact("צ'אט באתר: ללקוח לא מוגדרת סיסמת ניהול, יש ליצור קשר עם הלקוח עבור הגדרת סיסמת ניהול ", 1, this.userData.login, this.userData.MainPhone, this.userData.email, this.userData.firstname, this.userData.lastname, 2).subscribe(
        data => {
          console.log('success contact', data)
        },
        error => {
          console.log('error contact', error)
        }
      )
    }
    if (nextStep == "howManySitesThisMonth") {
      if (this.userData.quota <= 0) {
        this.sendMsg("nativ", "בחודש זה עברתם את מכסת האתרים שלכם ב " + Math.abs(this.userData.quota) + " אתרים,<br> האם אתם רוצים להמשיך להוספת אתר?", this.chatId, "robot")
      }
      else
        this.sendMsg("nativ", "נשארו לכם " + this.userData.quota + " אתרים להוספה החודש. האם תרצו להמשיך לבקשת האתר?", this.chatId, "robot")
    }
    if (nextStep == "sites") {
      this.existEmployees = await this.checkAgentAvailability()
      nextStep = this.mailOnly && this.existEmployees ? "mailOnlySitesExistsEmp" : this.mailOnly ? "mailOnlySitesNoExistsEmp" : nextStep
      this.nextStep = nextStep
    }
    if (nextStep == "showCountPromotion") {
      this.sendMsg("nativ", "מנוי זה יכול לקדם (לזרז) " + (this.countOfPromoteHave - this.countOfPromoteDid) + " מתוך " + this.countOfPromoteHave + "<br>האם אתם רוצים לקדם את בקשות האתרים שלכם?", this.chatId, "robot")
    }
    if (nextStep == "wantPromoteSite") {
      this.promoteSite()
    }
    if (nextStep == "sitesForThisNonth") {
      //const time=curStep == "grayList"?5000:0
      //setTimeout(() => {
      if (this.userData.quota <= 0) {
        this.sendMsg("nativ", "בחודש זה עברתם את מכסת האתרים שלכם ב " + Math.abs(this.userData.quota) + " אתרים,<br> האם אתם רוצים להמשיך להוספת אתר?", this.chatId, "robot")
      }
      else
        this.sendMsg("nativ", "נשארו לכם " + this.userData.quota + " אתרים להוספה החודש. האם תרצו להמשיך לבקשת האתר?", this.chatId, "robot")

      //}, time);
    }
    if (nextStep == "showSufring") {
      if (this.userData.stick.length == 1) {
        const simUsed = this.formatSimUsed(this.userData.stick[0].split("*")[1])
        this.sendMsg("nativ", simUsed + "% נוצלו מהחבילה", this.chatId, "robot")
        if (simUsed > 60) {
          this.sendMsg("nativ", this.chatProcess[nextStep].message, this.chatId, "robot")
        }
        else {
          this.next(curStep, "moreHelp")
          return
        }
      }
      else {
        for (let i = 0; i < this.userData.stick.length; i++) {
          const stickData = this.userData.stick[i].split("*");
          if (stickData[0] == this.ntloginSaved) {
            const simUsed = this.formatSimUsed(this.userData.stick[0].split("*")[1])
            this.sendMsg("nativ", simUsed + "% נוצלו מהחבילה", this.chatId, "robot")
            if (simUsed > 60) {
              this.sendMsg("nativ", this.chatProcess[nextStep].message, this.chatId, "robot")
              break
            }
            else {
              this.next(curStep, "moreHelp")
              return
            }
          }
        }
      }
    }
    else if (nextStep == "actionsInSim") {
      if (this.ntlogin) {
        var find = false
        for (let i = 0; i < this.userData.stick.length; i++) {
          const stickData = this.userData.stick[i].split("*");
          if (stickData[0] == this.ntlogin) {
            find = true
            this.showInputNtLogin = false
            this.ntloginSaved = this.ntlogin
            this.ntlogin = ""
            break
          }
        }
        if (!find) {
          console.log("not find ", curStep, nextStep, stepClick)
          this.ntlogin = ""
          this.sendMsg("nativ", "המספר סים שהכנסתם אינו נכון, הכניסו שוב.", this.chatId, "robot")
          this.chatProcNow[curStep] = false
          this.chatProcNow[curStep] = true
          //this.ntloginfirst = true
          //this.next(curStep, curStep)
          return
        }
      }
      if (this.userData.stickGiga.length == 1) {
        if (this.userData.stickGiga[0] == 100) {
          this.hasBiggerGiga = true
        }
      }
      else {
        for (let i = 0; i < this.userData.stick.length; i++) {
          const stickData = this.userData.stick[i].split("*");
          if (stickData[0] == this.ntloginSaved) {
            if (this.userData.stickGiga[i] == 100) {
              this.hasBiggerGiga = true
            }
          }
        }
      }
    }
    //todo - romeve this line
    //this.hasBiggerGiga = false
    if (this.chatProcess[nextStep] && this.chatProcess[nextStep].message && nextStep != "resetLicence" && nextStep != "promotionSite" && nextStep != "addNewLicence" && nextStep != "probLoginNativ") {
      this.sendMsg("nativ", this.chatProcess[nextStep].message, this.chatId, "robot")
    }
    if (nextStep == "afterOpenContactProbSite") {
      setTimeout(() => {
        this.sendMsg("nativ", "מספר פניה: " + this.idContact, this.chatId, "robot")
      }, 700);
    }
    if (nextStep == "checkHaveSiteEnterUrl") {
      this.siteNotWork = false
      this.isFromSiteNotWork = true
    }
    this.existEmployees = await this.checkAgentAvailability()
    if (nextStep == "lessThen5LicenceResetLastMonth" && !this.existEmployees) {
      this.apiService.contact("צ'אט באתר: משתמש ביקש לאפס רישיון בגלל חוסר ברישיונות", 1, this.userData.login, this.userData.MainPhone, this.userData.email, this.userData.firstname, this.userData.lastname, 2).subscribe(
        data => {
          console.log('success contact', data)
        },
        error => {
          console.log('error contact', error)
        }
      )
    }
    if (nextStep == "checkMailId") {
      if (!this.lastSite["MailID"]) {
        this.next("checkMailId", "moreHelp")
      }
      setTimeout(() => {
        this.checkMailId(this.lastSite["MailID"])
      }, 1500);
    }
    if (nextStep == "promotionSite") {
      this.isThing = true
      this.apiService.getWaitSites(this.userData.id).subscribe(
        data => {
          if (data["d"] != -1) {
            this.apiService.howManySitesWait().subscribe(
              data => {
                const countOfSiteWait = data["d"]
                //const countOfSiteWait = 250
                if (countOfSiteWait >= 220) {
                  this.promoteSite()
                }
                else {
                  this.apiService.getActionCount(this.userData.id, 2).subscribe(
                    data => {
                      console.log('success getActionCount', data["d"])
                      this.countOfPromoteDid = data["d"]
                      this.countOfPromoteHave = (3 * this.userData.nmboflicenses)
                      if (this.countOfPromoteDid < this.countOfPromoteHave || this.userData.usertypeid.charAt(4) == 1) {
                        this.next(nextStep, this.chatProcess["promotionSite"].nextStep.showCountPromotion)
                        this.isThing = false
                        return
                      }
                      else {
                        this.sendMsg("nativ", this.chatProcess["promotionSite"].message.Did1vipOr3PromotionThisMonth, this.chatId, "robot")
                        this.isThing = false
                        setTimeout(() => {
                          this.next(nextStep, this.chatProcess["promotionSite"].nextStep.promote)
                        }, 1500);
                        return
                      }
                    },
                    error => {
                      console.log("error get action count", error)
                      this.isThing = false;
                      this.next(this.currentStep, this.error)
                      return
                    }
                  )
                }
              },
              error => {
                console.log("error get action count", error)
                this.isThing = false;
                this.next(this.currentStep, this.error)
                return
              }
            )
          }
          else {
            console.log(data["d"])
            this.sendMsg("nativ", this.chatProcess["promotionSite"].message.haveNoSiteWait, this.chatId, "robot")
            this.isThing = false
            this.lastSite = data["lastSite"]
            setTimeout(() => {
              this.next(nextStep, this.chatProcess["promotionSite"].nextStep.haveNoSiteWait)
            }, 1500);
            return
          }
        }, error => {
          this.isThing = false;
          this.next(this.currentStep, this.error)
          return
        }
      )
    }
    if (nextStep == "resetOneLicence") {
      this.isThing = true
      this.apiService.resetLicence(this.userData.id, this.computerNameMinDate).subscribe(data => {
        if (data) {
          this.isThing = false
          console.log("success reset some licence")
        }
      }, error => {
        this.isThing = false;
        this.next(this.currentStep, this.error)
        return
      })
    }
    if (nextStep == "resetLicence") {
      this.chatProcNow[curStep] = false
      if (this.userData.nmboflicenses < 5 && !this.vipUser) {
        this.isThing = true
        this.apiService.getActionCount(this.userData.id, 1).subscribe(
          data => {
            if (data["d"] >= 1) {
              console.log("less Then 5 Licence Reset Last Month")
              this.next(nextStep, this.chatProcess["resetLicence"].nextStep.lessThen5LicenceResetLastMonth)
              this.isThing = false
            }
            else {
              console.log("less Then 5 Licence Not Reset Last Month")
              this.next(nextStep, this.chatProcess["resetLicence"].nextStep.lessThen5LicenceNotResetLastMonth)
              this.isThing = false
            }
          })
      }
      else {
        const isGetSerials = await this.getSerialsList()
        if (isGetSerials) {
          if (this.userData.nmboflicenses == 1) {
            console.log("vipUserWith1Licence")
            this.formattedMinDate = this.computerLastLogins[0]["computerLastLogin"]
            this.computerNameMinDate = this.computerNames[0]["computerName"]
            this.versionMinDate = this.enativVersions[0]["enativVersion"]
            this.next(nextStep, this.chatProcess["resetLicence"].nextStep.vipUserWith1Licence)
          }
          else {
            console.log("moreThen5Licence")
            const parsedDates = this.computerLastLogins.map(lastLogin => {
              const [year, month, day] = lastLogin["computerLastLogin"].split('/');
              return new Date(Number(year), Number(month) - 1, Number(day));
            });
            const minDate = new Date(Math.min.apply(null, parsedDates));
            const today = new Date();
            if (minDate.toDateString() === today.toDateString()) {
              this.next(nextStep, this.chatProcess["resetLicence"].nextStep.moreThen5LicenceNotFinfLastLogin)
            }
            else {
              this.formattedMinDate = `${minDate.getDate().toString().padStart(2, '0')}/${(minDate.getMonth() + 1).toString().padStart(2, '0')}/${minDate.getFullYear()}`;
              const computer = this.computerLastLogins.find(lastLogin => { lastLogin["computerLastLogin"] == this.formattedMinDate })
              const idMin = computer ? computer["id"] : 0
              this.computerNameMinDate = this.computerNames[idMin]["computerName"]
              this.versionMinDate = this.enativVersions[idMin]["enativVersion"]
              this.next(nextStep, this.chatProcess["resetLicence"].nextStep.moreThen5LicenceFinfLastLogin)
            }
          }
        }
      }
    }
    if (nextStep == "displayOldestComp") {
      setTimeout(() => {
        this.sendMsg("nativ", "שם מחשב: " + this.computerNameMinDate + "<br>גרסת נתיב: " + this.versionMinDate + "<br>תאריך כניסה אחרון: " + this.formattedMinDate, this.chatId, "robot")
      }, 600);
    }
    else if (nextStep == "simAction") {
      if (this.userData.stick) {
        if (this.userData.stick.length > 1) {
          const userDataStick = this.userData.stick;
          // userDataStick.splice(0, 1);
          this.sticksData = []
          userDataStick.forEach(stick => {
            const stickData = stick.split("*");
            const simUsed = this.formatSimUsed(stickData[1])
            const data = { simNum: stickData[0], simUsed: simUsed + "%", simCompany: (stickData[2] == 1 || stickData[2] == 3) ? "סלקום" : "019" }
            this.sticksData.push(data);
          });
          const companies = new Set(); // Using a Set to store unique company names
          for (const stick of this.sticksData) {
            companies.add(stick.simCompany);
          }
          const numCompanies = companies.size;
          if (numCompanies === 1) {
            console.log("All sticks have the same company.");
            console.log("Company:", this.sticksData[0].simCompany);
            this.next("simAction", this.chatProcess.simAction.nextStep.moreThanOneSimInCompany)
          } else {
            console.log("Multiple different companies found in the array.");
            console.log("Companies:", Array.from(companies));
            this.next("simAction", this.chatProcess.simAction.nextStep.oneSimForEachCompany)
          }
        }
        else {
          this.next("simAction", this.chatProcess.simAction.nextStep.oneSim)
        }
      }
    }
    if (nextStep == "letsOpenContact") {
      if (this.vipUser || this.userData.nmboflicenses >= 5) {
        setTimeout(() => {
          this.next(curStep, this.chatProcess[nextStep].nextStep.vipUser)
        }, 1500);
      }
      else {
        setTimeout(() => {
          this.next(curStep, this.chatProcess[nextStep].nextStep.regularUser)
        }, 1500);
      }
    }
    if (nextStep == "addNewLicence") {
      if (this.userData.path == "Private Basic") {
        this.sendMsg("nativ", this.chatProcess[nextStep].message.privateBasicPrice, this.chatId, "robot")
      }
      else if (this.userData.path == "Private+ ") {
        this.sendMsg("nativ", this.chatProcess[nextStep].message.privatePlusPrice, this.chatId, "robot")
      }
      else if (this.userData.path == "מייל בלבד") {
        this.sendMsg("nativ", this.chatProcess[nextStep].message.mailOnlyPrice, this.chatId, "robot")
      }
    }
    if (this.chatProcess[nextStep] && !this.chatProcess[nextStep].choice && this.chatProcess[nextStep].nextStep && nextStep != "simAction" && nextStep != "promotionSite" && nextStep != "resetLicence" && !this.chatProcess[nextStep].inputStep) {
      setTimeout(() => {
        this.next(nextStep, this.chatProcess[nextStep].nextStep)
      }, 1500);
    }
    this.chatProcNow[curStep] = false
    setTimeout(() => {
      this.chatProcNow[nextStep] = true
    }, 800);
    this.checkURL = true
    if (this.disconnect) {
      this.enableSubmit = true;
      this.authenticated = false;
      setTimeout(() => {
        this.sendMsg("nativ", "אירעה שגיאת חיבור", this.chatId, "robot")
      }, 500);
      setTimeout(() => {
        this.sendMsg("nativ", "אנא הכנס שם משתמש וסיסמה", this.chatId, "robot")
      }, 1500);
      setTimeout(() => {
        this.chatProcNow["start"] = true
      }, 2500);
    }
  }

  //check if user has the site or not 
  hasUserSite(): Promise<string> {
    this.isThing = true
    return new Promise<string>((resolve, reject) => {
      this.apiService.hasUserSite(this.userData.id, this.url).subscribe(
        (data) => {
          this.isThing = false
          if (data["d"] == 2) {
            resolve("siteNoAllowed");
            console.log("=========================== siteNoAllowed");
          }
          else if (data["d"] == 1) {
            resolve("record");
          }
          else {
            resolve("hasNoSite");
          }
        },
        (error) => {
          this.isThing = false
          console.log("error from hasUserSite", error);
          reject(error);
          this.next(this.currentStep, this.error)
          return;
        }
      );
    });
  }
  hasUserWaitSite(): Promise<string> {
    this.isThing = true
    return new Promise<string>((resolve, reject) => {
      this.apiService.hasUserWaitSite(this.userData.id, this.url).subscribe(
        (data) => {
          this.isThing = false
          if (data["d"]) {
            resolve("siteWaitNotWorkSite");
            console.log("siteWaitNotWorkSite");
          }
          else {
            resolve("checkHaveSite");
          }
        },
        (error) => {
          this.isThing = false
          console.log("error from hasUserSite", error);
          reject(error);
          this.next(this.currentStep, this.error)
          return;
        }
      );
    });
  }

  promoteSite() {
    this.isThing = true
    this.apiService.setPrioritySite(this.userData.id).subscribe(
      data => {
        console.log('success setPrioritySite', data["d"])
        if (data["d"] > 0) {
          console.log(data["d"])
          this.apiService.existSitesEmployees().subscribe(
            data => {
              console.log('success existSitesEmployees', data)
              if (data["d"] && data["d"].length == 0) {
                this.sendMsg("nativ", this.chatProcess["promotionSite"].message.promoteThereAreNoEmp, this.chatId, "robot")
              }
              else if (data["d"] && data["d"].length != 0) {
                this.sendMsg("nativ", this.chatProcess["promotionSite"].message.promoteThereAreEmp, this.chatId, "robot")
              }
              this.isThing = false
              setTimeout(() => {
                this.next("promotionSite", this.chatProcess["promotionSite"].nextStep.promote)
              }, 1500);
              return
            },
            error => {
              console.log('error existSitesEmployees', error)
              this.isThing = false;
              this.next(this.currentStep, this.error)
              return
            }
          )
        }
        else if (data["d"] == 0) {
          console.log(data["d"])
          this.sendMsg("nativ", this.chatProcess["promotionSite"].message.haveNoSiteToPromote, this.chatId, "robot")
          this.isThing = false
          setTimeout(() => {
            this.next("promotionSite", this.chatProcess["promotionSite"].nextStep.promote)
          }, 1500);
          return
        }
        else if (data["d"] == -1) {
          console.log(data["d"])
          this.sendMsg("nativ", this.chatProcess["promotionSite"].message.haveNoSiteWait, this.chatId, "robot")
          this.isThing = false
          this.lastSite = data["lastSite"]
          setTimeout(() => {
            this.next("promotionSite", this.chatProcess["promotionSite"].nextStep.haveNoSiteWait)
          }, 1500);
          return
        }
        else {
          this.sendMsg("nativ", this.chatProcess["promotionSite"].message.notPromote, this.chatId, "robot")
          this.isThing = false
          setTimeout(() => {
            this.next("promotionSite", this.chatProcess["promotionSite"].nextStep.notPromote)
          }, 1500);
          return
          //}, timeout);
        }
      },
      error => {
        console.log('error setPrioritySite', error)
        this.sendMsg("nativ", this.chatProcess["promotionSite"].message.notPromote, this.chatId, "robot")
        this.isThing = false
        this.next("promotionSite", this.chatProcess["promotionSite"].nextStep.notPromote)
        return
        //}, timeout);
      }
    )
  }

  getSerialsList(): Promise<string> {
    this.isThing = true
    return new Promise<string>((resolve, reject) => {
      try {
        this.apiService.getUserData(this.userData.login, this.userData?.id).subscribe(
          data => {
            this.details = data;
            this.userDataDetails = this.details["userData"];
            this.userDataForSerials = this.userDataDetails.d;
            console.log('success getUserData', this.userDataForSerials)
            if (this.userDataForSerials.serials && this.userDataForSerials.serials.length) {
              const userDataSerials = this.userDataForSerials.serials
              this.serialsData = []
              this.serialsFilterData = []
              userDataSerials.forEach((serial, index) => {
                const serialData = serial.split("*");
                const data = { computerName: serialData[0], computerLastLogin: serialData[1], enativVersion: serialData[2], login: serialData[4] }
                this.serialsData.push(data);
                this.computerNames.push({ id: index + 1, computerName: serialData[0] });
                const lastDate = serialData[1]
                const formattedDate = this.datePipe.transform(lastDate, 'd/M/yyyy');
                this.computerLastLogins.push({ id: index + 1, computerLastLogin: formattedDate });
                this.enativVersions.push({ id: index + 1, enativVersion: serialData[2] });
                this.logins.push({ id: index + 1, login: serialData[4] });
                this.isThing = false
              });
              resolve("true")
              // this.serialsData.sort((a, b) => {
              //   const dateA = new Date(b.computerLastLogin);
              //   const dateB = new Date(a.computerLastLogin);
              //   return dateA.getTime() - dateB.getTime();
              // });
              // this.serialsData.sort((a, b) => a.login.localeCompare(b.login));
              // this.serialsFilterData = this.serialsData.slice();
              // this.sortLicenseArrays();
              // this.logins = this.logins.filter((obj, index, self) =>
              //   index === self.findIndex((o) => (
              //     o.login === obj.login
              //   ))
              // );
              // this.logins.sort((a, b) => a.login.localeCompare(b.login));
            }
            else if (this.userDataForSerials.serials == null) {
              this.isThing = false
              this.sendMsg("nativ", "הרשיון שלכם כבר מאופס", this.chatId, "robot")
              this.next("", "moreHelp")
              reject("false")
            }
          }, error => {
            this.isThing = false;
            this.next(this.currentStep, this.error)
            return
          })
      }
      catch (error) {
        reject(error)
        this.isThing = false;
        this.next(this.currentStep, this.error)
        return
      }
    });
  }
  // function updateUser(adminId, userId, updateData,updateCB) {
  //     $.ajax({
  //         url: 'UserService.asmx/UpdateUser',
  //         data: JSON.stringify({ adminId, userId, updateData }),
  //         type: "POST",
  //         contentType: "application/json",
  //         success: function (response) {
  //             updateCB(response)
  //         },
  //         error: function (jqXHR, textStatus, errorThrown) {
  //             var error = textStatus;
  //             updateCB({ error })
  //         }
  //     });
  // }

  formatSimUsed(used) {
    const usedArr = used.split(".")
    const simUsed = usedArr.length > 1 ? usedArr[0] + "." + usedArr[1][0] : used
    return simUsed
  }

  //not used
  showGigaOption(gigaOption) {
    const giga = this.userData.stickGiga
    const stickData = this.userData.stick
    if (giga.length == 1) {
      if (giga[0] > gigaOption) {
        return false
      }
      else {
        return true
      }
    }
    else {
      for (let i = 0; i < stickData.length; i++) {
        const ntlogini = stickData[i].split("*")[0];
        if (ntlogini == this.ntloginSaved) {
          if (giga[i] > gigaOption) {
            return false
          }
          else {
            return true
          }
        }
      }
    }
  }

  //on submit money contact, send the contact to money apartment
  onSubmitMoney(formData: any, modal, depNumber) {
    this.submittedMoney = true;
    this.enableSubmitMoney = false;
    if (this.contactFormMoney.invalid) {
      this.enableSubmitMoney = true;
      return;
    }
    const phoneNumber = formData.phonenumber.replace(/\D/g, '');
    if (phoneNumber.length < 9 || phoneNumber.length > 15) {
      this.contactFormMoney.get('phonenumber').setErrors('invalid phonenumber')
      this.validPhoneNumber = false;
      this.enableSubmitMoney = true;
      return
    }
    const userArgent = window.navigator.userAgent
    if (this.depNumber !== 3 && (userArgent.includes("Linux") || userArgent.includes("Android") || userArgent.includes("iPhone OS"))) {
      modal.dismiss()
      Swal.fire("", "ניתן לפתוח פניות בנושא סינון למחשבים רק ממחשב", 'error')
      this.enableSubmitMoney = true;
      return;
    }
    try {
      this.contactFormMoney.reset();
      modal.dismiss('GOOD')
      if (this.urlParams["pid"] && this.urlParams["dpt"]) {
        this.apiService.updateProcpect(this.urlParams["pid"], this.urlParams["dpt"], formData.content).subscribe(
          data => {
            console.log('success updateProcpect', data)
            this.enableSubmitMoney = true;
            Swal.fire("", "פנייתך התקבלה ותטופל בהקדם", 'success')
          },
          error => {
            console.log('error updateProcpect', error)
            this.enableSubmitMoney = true;
            this.contacted = false;
            console.log('error contact', error)
            Swal.fire("", "אירעה שגיאה בשליחת הפניה", 'error')
          }
        )
        if (this.userData.gviaproblem != 0) {
          this.next('openContactAcountApartment', 'final')
          return;
        }
        else {
          this.next('openContactAcountApartment', 'moreHelp')
          return;
        }
      }
      else {
        const login = formData.username ? formData.username : this.authenticationService.userData && this.authenticationService.userData.login ? this.authenticationService.userData.login : 'NONE';
        formData.phonenumber = formData.phonenumber.replace(/\D/g, '');
        const name = formData.fullname.split(" ")
        const fname = name[0];
        const lname = name[1] ? name[1] : "";
        this.apiService.contact(formData.content, depNumber, login, formData.phonenumber, formData.email, fname, lname, this.priority).subscribe(
          data => {
            console.log('success contact', data)
            this.enableSubmitMoney = true;
            Swal.fire("", "פנייתך התקבלה ותטופל בהקדם", 'success')
          },
          error => {
            this.enableSubmitMoney = true;
            this.contacted = false;
            console.log('error contact', error)
            Swal.fire("", "אירעה שגיאה בשליחת הפניה", 'error')
          }
        )
        if (this.userData.gviaproblem != 0) {
          this.next('openContactAcountApartment', 'final')
          return;
        }
        else {
          this.next('openContactAcountApartment', 'moreHelp')
          return;
        }
      }
    } catch (error) {
      console.log(error)
      Swal.fire("", "אירעה שגיאה בשליחת הפניה", 'error')
      if (this.userData.gviaproblem != 0) {
        this.next('openContactAcountApartment', 'final')
        return;
      }
      else {
        this.next('openContactAcountApartment', 'moreHelp')
        return;
      }
    }
  }

  //add site to user by chat
  addSite() {
    this.checkSitesCode = false
    this.confirmOverQuote = false
    let siteData = { username: this.userData.login, sites: JSON.stringify([{ url: this.url, comments: "" }]), email: this.mail }
    console.log('add site has been submitted', siteData);
    try {
      const windowsUsers = this.userData.WindowsUsers ? (Object.keys(this.userData.WindowsUsers)[0] + "-" + Object.values(this.userData.WindowsUsers)[0]) : ""
      const windowsMachine = this.userData.WindowsMachine ? this.userData.WindowsMachine : ""
      const nativVersion = this.userData.NativVersion ? this.userData.NativVersion : ""
      const reqIp = ""
      const reqData = JSON.stringify({
        cats: "", dec: -1, sid: -1, ref: "",
        windowsMachine, windowsUsers: encodeURI(windowsUsers), nativVersion, reqIp
      })
      this.isThing = true
      this.apiService.addSite(siteData, reqData).subscribe(
        data => {
          console.log('success send addSite', data)
          if (data["d"]) {
            if (data["d"] == -9) {
              this.isThing = false
              //Swal.fire("", "קיימת כבר בקשה לאתר זה", 'warning')
              this.sendMsg("nativ", "קיימת כבר בקשה לאתר זה", this.chatId, "robot")
              this.next("waitOrOtomat", "moreHelp")
              console.log('error addSite', data)
              this.openSite = true
              this.url = ""
              this.mail = ""
              this.checkURL = true
              this.showInputUrl = false
            }
            else if (data["d"] == -99) {
              this.isThing = false
              this.sendMsg("nativ", "אירעה שגיאה בשליחת הבקשה", this.chatId, "robot")
              this.next("waitOrOtomat", "moreHelp")
              console.log('error addSite', data)
              this.openSite = true
              this.url = "https://"
              this.mail = ""
              this.checkURL = true
              this.showInputUrl = false
            }
            else {
              this.userId = this.userData.id
              console.log(this.userId, this.url)
              this.apiService.isPendingSite(this.userId, this.url).subscribe(
                data => {
                  if (data["d"] == true) {
                    this.next('waitOrOtomat', this.chatProcess.waitOrOtomat.nextStep.wait)
                  }
                  else {
                    const mailId = data["d"][data["d"].length - 1]["mailId"]
                    this.checkMailId(mailId)
                    //this.router.navigate(['/my-sites']);
                  }
                  this.isThing = false
                  this.openSite = true
                  this.url = ""
                  this.mail = ""
                  this.checkURL = true
                  this.showInputUrl = false
                },
                error => {
                  this.isThing = false
                  this.next(this.currentStep, this.error)
                  return;
                }
              )
            }
          }
          else {
            this.isThing = false
            console.log('error addSite', data)
            this.next(this.currentStep, this.error)
            return
            // this.openSite = true
          }
        },
        error => {
          this.isThing = false
          this.next(this.currentStep, this.error)
          this.enableSubmit = true;
          console.log('error addSite', error)
          return
          // this.openSite = true
        }
      )
    }
    catch (error) {
      console.log(error)
      this.enableSubmit = true;
      this.isThing = false
      this.next(this.currentStep, this.error)
    }
  }

  checkMailId(mailId) {
    console.log("===================================", this.chatProcess.waitOrOtomat.nextStep.otomat, mailId)
    switch (mailId) {
      case 3: case 7:
        this.next('waitOrOtomat', this.chatProcess.waitOrOtomat.nextStep.otomat.mail37)
        break;
      case 1: case 12:
        this.next('waitOrOtomat', this.chatProcess.waitOrOtomat.nextStep.otomat.mail112)
        break;
      case 2: case 4: case 5: case 6: case 8: case 9: case 10: case 11: case 13: case 14:
      case 15: case 16: case 17: case 18: case 19: case 20: case 21: case 22: case 23:
      case 24: case 25: case 26: case 27: case 28: case 29: case 30: case 31: case 32:
      case 33: case 34: case 35: case 36: case 37:
        this.next('waitOrOtomat', this.chatProcess.waitOrOtomat.nextStep.otomat.mailRest)
        break;
      case null:
        this.next('waitOrOtomat', this.chatProcess.waitOrOtomat.nextStep.otomat.null)
        break;
      default:
        this.next('waitOrOtomat', this.chatProcess.waitOrOtomat.nextStep.otomat.notKnowMail)
        break;
    }
  }
  navigateUserProfile() {
    this.authGuardService.setButtonClickUserProfile(true);
    this.router.navigate(['/user-profile']);
  }

  //check if url valid or not
  async checkUrl() {
    if (this.checkUrlCount < 3)
      this.sendMsg("user", this.url, this.chatId, this.writeName)
    if (this.inputStep == "openSite") {
      if (this.userData.ReceiveMailMessage == "2") {
        this.addSite()
      }
      else {
        if (this.vipUser && this.userData.nmboflicence > 5) {
          this.next("checkVipUser", "enterMail")
        }
        else {
          this.next("checkVipUser", "whichMail")
        }
      }
      this.showInputUrl = false
      return
    }
    this.checkURL = false
    this.errorURL = ""
    const hebrewPattern = /[\u0590-\u05FF]/;
    if (this.checkUrlCount < 3) {
      if (this.url == null || this.url == "" || this.url == "https://") {
        this.sendMsg("nativ", "אנא הכניסו כתובת אתר", this.chatId, "robot")
        this.checkURL = true
        this.checkUrlCount++
        return
      }
      else if (hebrewPattern.test(this.url) || this.url.trim().length < 4 || this.url.indexOf("stick.enativ.com") > -1 || this.urlFormControl.hasError('pattern')) {
        if (this.checkUrlCount == 2) {
          this.checkUrlCount++;
          this.showInputUrl = false
          this.checkUrl()
          return;
        }
        this.sendMsg("nativ", "לא הצלחתי להבין באיזה אתר מדובר. אנא הקלידו שוב את כתובת האתר באנגלית כדי שנוכל להמשיך", this.chatId, "robot")
        this.checkURL = true
        this.checkUrlCount++
        this.url = ""
        return
      }
    }
    else {
      this.showInputUrl = false
      this.sendMsg("nativ", "ממש סליחה אבל הכתובות לא נכונות.", this.chatId, "robot")
      if (this.inputStep == "checkHaveSiteEnterUrl") {
        this.existEmployees = await this.checkAgentAvailability()
        if (this.existEmployees) {
          setTimeout(() => {
            this.next("checkHaveSiteEnterUrl", "employeeOrRecord")
          }, 1000);
          this.checkUrlCount = 0
          this.url = ""
          return
        }
        else {
          setTimeout(() => {
            this.sendMsg("nativ", "יש לי דרך נוספת לעזור לכם. לצורך זה יש לבצע הקלטת גלישה.", this.chatId, "robot")
          }, 1000);
          setTimeout(() => {
            this.next("checkHaveSiteEnterUrl", "record")
          }, 2000);
          this.checkUrlCount = 0
          this.url = ""
          return
        }
      }
      else if (this.inputStep == "writeUrl") {
        this.existEmployees = await this.checkAgentAvailability()
        if (this.existEmployees) {
          setTimeout(() => {
            this.notHome = true;
            this.next(this.inputStep, "notSuccessWriteUrlExistsEmp")
          }, 1000);
          this.checkUrlCount = 0
          this.url = ""
          return
        }
        else {
          setTimeout(() => {
            this.next(this.inputStep, "notSuccessWriteUrlNoExistsEmp")
          }, 1000);
          this.checkUrlCount = 0
          this.url = ""
          return
        }
      }
    }


    const nextStep = this.chatProcess[this.inputStep].choice ? this.chatProcess[this.inputStep].choice[0].nextStep : this.chatProcess[this.inputStep].nextStep
    this.nextStep = nextStep
    this.next(this.inputStep, nextStep)

    this.checkUrlCount = 0
    this.urlForContact = this.url
    this.url = ""
    this.showInputUrl = false
  }

  checkPass() {
    if (this.checkPassCount < 3) {
      if (this.passwordInput == null || this.passwordInput == "") {
        this.sendMsg("nativ", "אנא הכניסו סיסמה", this.chatId, "robot")
        this.checkPassCount++
        return
      }
      if (this.userData.password == this.passwordInput) {
        this.next("enterPassword", this.chatProcess["enterPassword"].nextStep, this.passwordInput)
        this.checkPassCount = 0
      }
      else {
        this.sendMsg("nativ", "הפרטים שגויים, אנא הכניסו פרטים נכונים", this.chatId, "robot")
        this.checkPassCount++
        return
      }
    }
    else {

    }
  }

  async checkManagerPass() {
    this.sendMsg("user", this.managerPassword, this.chatId, this.writeName)
    if (this.checkManagerPassCount < 3) {
      if (this.managerPassword == null || this.managerPassword == "") {
        this.sendMsg("nativ", "אנא הכניסו סיסמה", this.chatId, "robot")
        this.managerPassword = ""
        this.checkManagerPassCount++
        return
      }
      if (this.userData.ManagerPassword == this.managerPassword) {//verify success
        if (this.numOfLicenses > 0)//add license
          this.next(this.inputStep, this.chatProcess[this.inputStep].nextStep.addLicenseValidationSuccess)
        else {//resetOneLicence
          this.next(this.inputStep, this.chatProcess[this.inputStep].nextStep.resetOneLicence)
        }
        this.showInputManagerPass = false
        this.managerPassword = ""
        this.checkManagerPassCount = 0
      }
      else {
        this.sendMsg("nativ", "סיסמא לא נכונה<br>הקלידו את סיסמת ניהול החשבון", this.chatId, "robot")
        this.managerPassword = ""
        this.checkManagerPassCount++
        return
      }
    }
    else {
      this.existEmployees = await this.checkAgentAvailability()
      if (this.existEmployees) {
        this.next(this.inputStep, this.chatProcess[this.inputStep].nextStepEmployee)
      }
      else {
        this.sendMsg("nativ", "ניסינו יותר מדי פעמים", this.chatId, "robot")
        setTimeout(() => {
          this.next(this.inputStep, this.chatProcess[this.inputStep].nextStepNoEmployee)
        }, 1500);
      }
      this.showInputManagerPass = false
      this.managerPassword = ""
    }
  }

  checkSitesCodeF() {
    this.sendMsg("user", this.sitesCode, this.chatId, this.writeName)
    if (this.userData.AddSitesCode == this.sitesCode) {
      this.checkSitesCode = true
      this.showInputSitesCode = false
      this.sitesCode = ""
      this.next(this.inputStep, this.chatProcess[this.inputStep].nextStep)
    }
    else {
      this.sitesCode = ""
      this.sendMsg("nativ", "הקוד לא תקין", this.chatId, this.writeName)
    }
  }

  checkMail() {
    this.sendMsg("user", this.mail, this.chatId, this.writeName)
    if (this.emailFormControl.hasError('required')) {
      this.mail = ""
      this.sendMsg("nativ", "אנא הכניסו כתובת מייל", this.chatId, this.writeName)
    }
    else if (this.emailFormControl.hasError('email') || this.emailFormControl.hasError('pattern')) {
      this.mail = ""
      this.sendMsg("nativ", "כתובת המייל שהכנסתם אינה תקינה", this.chatId, this.writeName)
    }
    else {
      this.showInputMail = false
      this.next(this.inputStep, this.chatProcess[this.inputStep].nextStep)
    }
  }

  checkPhone() {
    this.sendMsg("user", this.phone, this.chatId, this.writeName)
    if (this.phoneFormControl.hasError('required')) {
      this.phone = ""
      this.sendMsg("nativ", "אנא הכניסו מספר פלאפון", this.chatId, this.writeName)
    }
    else if (this.phoneFormControl.hasError('pattern')) {
      this.phone = ""
      this.sendMsg("nativ", "מספר הפלאפון שהכנסתם אינו תקין", this.chatId, this.writeName)
    }
    else {
      this.showInputPhone = false
      this.next(this.inputStep, this.chatProcess[this.inputStep].nextStep)
    }
  }

  checkNumLicenses() {
    this.sendMsg("user", this.numOfLicenses, this.chatId, this.writeName)
    if (!this.numOfLicenses || this.numOfLicenses < 1 || !this.isInteger(this.numOfLicenses)) {
      this.sendMsg("nativ", "מספר הרשיונות להוספה שהכנסתם לא תקין", this.chatId, "robot")
      this.numOfLicenses = ""
    }
    else {
      this.showInputNumLicense = false;
      let licensePath = 0
      if (this.userData.path == "Private Basic") {
        licensePath = 22.5
      }
      else if (this.userData.path == "Private+ ") {
        licensePath = 27.5
      }
      else if (this.userData.path == "מייל בלבד") {
        licensePath = 14.5
      }
      let licenseSum = licensePath * this.numOfLicenses
      let oneTimePayment = this.numOfLicenses * 100
      this.sendMsg("nativ", "סה''כ עלות חד''פ " + oneTimePayment + " ש''ח<br>סה''כ תוספת תשלום לחשבון החודשי - " + licenseSum + " ש''ח", this.chatId, "robot")
      this.next(this.currentStep, this.chatProcess["checkAmountLicensesToAdd"].nextStep)
    }
  }
  async sendMsg(type, msg, chatId, whoSend) {
    const imgSrc = type == "nativ" ? this.nativImageSrc : this.userImgSrc
    this.updatedTime()
    this.messages.push({ text: msg, imgSrc: imgSrc, type: type, time: this.currentTime })
    await this.saveChatMessage(msg, chatId, whoSend, this.fullCurrentTime);
  }

  async saveChatMessage(msg, chatId, whoSend, fullCurrentTime): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.apiService.saveChatMsg(msg, chatId, whoSend, fullCurrentTime).subscribe(
        data => {
          if (data["d"]) {
            console.log("Success: save chat message");
            resolve(data["d"]);
          }
        },
        error => {
          console.log("Error: save chat message", error);
          reject(error);
        }
      );
    });
  }

  puyTypeProb(type) {
    this.typeProb = type
  }

  sendFreeText() {
    this.next(this.inputStep, this.chatProcess[this.inputStep].nextStep, this.freeText)
    this.showInputFreeText = false
    this.freeText = ""
  }

  connectSoftware() {
    // this.sendMsg("nativ", "יש להתחבר לתוכנת נתיב", this.chatId, "robot")
    window.location.href = this.loginLink;
  }

  getCurrentTime() {
    const israelTime = moment().tz('Asia/Jerusalem');
    this.currentDate = israelTime.format('HH:mm:ss');
    this.currentHour = israelTime.hour();
    this.currentDay = israelTime.day();
  }

  updatedTime() {
    // this.update = new Date()
    const update = moment().tz('Asia/Jerusalem');
    this.hours = update.hours().toString().padStart(2, '0');
    this.minutes = update.minutes().toString().padStart(2, '0');
    this.seconds = update.seconds().toString().padStart(2, '0');
    this.currentTime = `${this.hours}:${this.minutes}`;
    this.fullCurrentTime = `${this.hours}:${this.minutes}:${this.seconds}`
  }

  change1(item) {
    this.checklist1.forEach(val => {
      if (val.id == item.id) {
        val.isSelected = !val.isSelected
      }
      else {
        val.isSelected = false;
      }
    });
    this.question1Val = item.value
    this.sendMsg("user", this.question1Val, this.chatId, this.writeName)
    this.next(this.nextStep, this.chatProcess[this.nextStep].nextStep)
  }
  change2(item) {
    this.checklist2.forEach(val => {
      if (val.id == item.id) {
        val.isSelected = !val.isSelected;
      }
      else {
        val.isSelected = false;
      }
    });
    this.question2Val = item.value
    this.sendMsg("user", this.question2Val, this.chatId, this.writeName)
    this.next(this.nextStep, this.chatProcess[this.nextStep].nextStep)

  }
  change3(item) {
    this.checklist3.forEach(val => {
      if (val.id == item.id) {
        val.isSelected = !val.isSelected
      }
      else {
        val.isSelected = false;
      }
    });
    this.question3Val = item.value
    this.sendMsg("user", this.question3Val, this.chatId, this.writeName)
    this.next(this.nextStep, this.chatProcess[this.nextStep].nextStep)

  }

  isUpdatedVersion() {
    return new Promise((resolve, reject) => {
      this.nativVersion = this.authenticationService.userData.appNativVersion ? this.authenticationService.userData.appNativVersion : this.nativVersion
      const splitVersion = this.nativVersion.split(".")
      if (parseInt(splitVersion[0]) == 4 && parseInt(splitVersion[1]) == 5) {
        this.apiService.getLastNativVersion().subscribe(
          data => {
            const lastVersion = data["res"]
            if (lastVersion != "" && this.nativVersion !== lastVersion) {
              const splitLastVersion = lastVersion.split(".")
              if (parseInt(splitVersion[2]) < parseInt(splitLastVersion[2])) {
                this.updatedVersion = false
              }
              else if (parseInt(splitVersion[2]) == parseInt(splitLastVersion[2])) {
                if (parseInt(splitVersion[3]) < parseInt(splitLastVersion[3])) {
                  this.updatedVersion = false
                }
              }
            }
            resolve("update version"); // The function has completed, continue with the code
          },
          error => {
            console.log('error getLastNativVersion', error)
            reject(error); // Reject the promise if an error occurs
          }
        )
      }
      else {
        this.updatedVersion = false
        resolve("no update version"); // The function has completed, continue with the code
      }
    });
  }

  async checkAgentAvailability(): Promise<boolean> {
    const israelTime = moment().tz('Asia/Jerusalem');
    this.currentDate = israelTime.format('HH:mm:ss');
    this.currentHour = israelTime.hour();
    this.currentMinute = israelTime.minute();
    this.currentDay = israelTime.day();

    if ((this.currentHour < this.techCloseHour.hour || (this.currentHour == this.techCloseHour.hour && this.currentMinute < this.techCloseHour.minute))
      && this.currentHour >= this.techOpeneHour.hour
      && this.currentDay < 5) {
      try {
        const data = await this.apiService.existEmployees().toPromise();
        console.log('success existEmployees', data);
        return data["d"] ? true : false;
      } catch (error) {
        console.log('error existEmployees', error);
        return false;
      }
    } else {
      return false;
    }
  }


  blockSite(): Promise<string> {
    this.isThing = true
    const siteData = {}
    siteData["sites"] = [{ url: this.urlForContact }]
    siteData["sites"] = JSON.stringify(siteData["sites"]);
    console.log('remove site has been submitted', siteData);
    return new Promise<string>((resolve, reject) => {
      try {
        this.apiService.removeSite(siteData).subscribe(
          data => {
            if (data["d"]) {
              this.userBlockSite = true
              resolve("true")
            }
            else {
              reject("false")
            }
            this.isThing = false;
          },
          error => {
            console.log('error removeSite', error)
            this.isThing = false;
            reject("false")
          }
        )
      }
      catch (error) {
        console.log(error)
        this.isThing = false;
        reject("false")
      }
    });
  }

  isInteger(str) {
    return /^\d+$/.test(str);
  }
}