<div class="row">
        <div class="col-12">
                <div class="c8 doc-content main-div">
                        <p class="c5"><span class="c1">Privacy Policy<br></span><span class="c3">&nbsp;Effective Date:
                                        19/02/2025</span></p>
                        <p class="c5"><span class="c3">Nativ (&quot;we,&quot; &quot;our,&quot; or &quot;us&quot;) is
                                        committed to protecting
                                        your privacy. This Privacy Policy explains how we collect, use, and share
                                        information when you use our
                                        Chrome extension (the &quot;Extension&quot;). By installing and using the
                                        Extension, you agree to the
                                        practices described in this Privacy Policy.</span></p>
                        <h3 class="c0" id="h.4m0xeslh28xo"><span class="c4 c1">1. Information We Collect</span></h3>
                        <p class="c5"><span class="c3">When you use the Extension, we may collect the following
                                        information:</span></p>
                        <ul class="c6 lst-kix_t6trewb8wvpk-0 start">
                                <li class="c2 li-bullet-0"><span class="c1">Photos</span><span class="c3">: The
                                                Extension allows you to send
                                                images from the website you are visiting to Nativ for evaluation.</span>
                                </li>
                                <li class="c2 li-bullet-0"><span class="c1">Metadata</span><span class="c3">: This may
                                                include image-related
                                                metadata such as resolution, format, and URL source.</span></li>
                                <li class="c2 li-bullet-0"><span class="c1">Usage Data</span><span class="c3">: We may
                                                collect anonymous data
                                                about how you use the Extension to improve its functionality.</span>
                                </li>
                        </ul>
                        <h3 class="c0" id="h.h0pptjgvwzxy"><span class="c4 c1">2. How We Use Your Information</span>
                        </h3>
                        <p class="c5"><span class="c3">We use the collected information for the following
                                        purposes:</span></p>
                        <ul class="c6 lst-kix_ed7avsmlqs62-0 start">
                                <li class="c2 li-bullet-0"><span class="c3">To evaluate and process the images you
                                                submit.</span></li>
                                <li class="c2 li-bullet-0"><span class="c3">To improve the functionality and performance
                                                of the
                                                Extension.</span></li>
                                <li class="c2 li-bullet-0"><span class="c3">To ensure compliance with our terms of
                                                service and policies.</span>
                                </li>
                                <li class="c2 li-bullet-0"><span class="c3">To provide customer support if
                                                needed.</span></li>
                        </ul>
                        <h3 class="c0" id="h.9p2gfk55m303"><span class="c4 c1">3. How We Share Your Information</span>
                        </h3>
                        <p class="c5"><span class="c3">We do not sell or rent your information. However, we may share
                                        information in the
                                        following circumstances:</span></p>
                        <ul class="c6 lst-kix_rf12kci1ud7u-0 start">
                                <li class="c2 li-bullet-0"><span class="c1">With Service Providers</span><span
                                                class="c3">: We will not share
                                                information with third-party service providers.</span></li>
                                <li class="c2 li-bullet-0"><span class="c1">For Legal Compliance</span><span>: In
                                                accordance with applicable
                                                laws, we may be obligated to disclose your information to governmental
                                                authorities or other pertinent
                                                parties.</span></li>
                                <li class="c2 li-bullet-0"><span class="c1">With Your Consent</span><span class="c3">:
                                                If we need to share your
                                                information for any other purpose, we will seek your explicit
                                                consent.</span></li>
                        </ul>
                        <h3 class="c0" id="h.a6rpkmttevnl"><span class="c4 c1">4. Data Retention</span></h3>
                        <p class="c5"><span class="c3">We retain collected data for as long as necessary to fulfill the
                                        purposes outlined in
                                        this Privacy Policy. If you wish to request the deletion of your submitted
                                        images or data, you can contact
                                        us at [Insert Contact Email].</span></p>
                        <h3 class="c0" id="h.4dhtq4hhws03"><span class="c4 c1">5. Security Measures</span></h3>
                        <p class="c5"><span class="c3">We implement industry-standard security measures to protect your
                                        data from
                                        unauthorized access, alteration, disclosure, or destruction. However, no online
                                        service is completely
                                        secure, and we cannot guarantee absolute security.</span></p>
                        <h3 class="c0" id="h.5orgqda9fyp7"><span class="c4 c1">6. Your Choices and Rights</span></h3>
                        <ul class="c6 lst-kix_ip7ny5xsewzc-0 start">
                                <li class="c2 li-bullet-0"><span class="c3">You may choose not to use the Extension if
                                                you do not agree with
                                                this Privacy Policy.</span></li>
                                <li class="c2 li-bullet-0"><span class="c3">You can contact us at 166@enativ.com to
                                                request access to,
                                                correction, or deletion of your data.</span></li>
                        </ul>
                        <h3 class="c0" id="h.xpwk6w2eknxv"><span class="c4 c1">7. Changes to This Privacy Policy</span>
                        </h3>
                        <p class="c5"><span class="c3">We may update this Privacy Policy from time to time. Any changes
                                        will be posted
                                        within the Extension, and your continued use of the Extension after changes are
                                        made constitutes your
                                        acceptance of the revised policy.</span></p>
                        <h3 class="c0" id="h.desla6imb7uq"><span class="c1 c4">8. Contact Us</span></h3>
                        <p class="c5"><span>If you have any questions or concerns about this Privacy Policy, please
                                        contact us at:
                                </span><span class="c1">Nativ<br></span><span class="c3">&nbsp;Email: 166@enativ.com<br>
                                        Website:
                                        www.enativ.com</span></p>
                        <p class="c7"><span class="c3"></span></p>
                </div>
        </div>
</div>